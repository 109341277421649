import {HOME, HIDDEN_HEADER} from "../types";

const initialState = {
    data: {
        id: 0,
        about_us_text: '',
        about_us_video: '',
        logo: '',
        principal_image: '',
        principal_subtitle: '',
        principal_text: '',
        principal_title: '',
        video: '',
        partners: [],
        posts: [],
        reasons: [],
        steps: [],
        testimonies: [],
        address: '',
        phone: '',
        email: '',
        city: '',
        twitter: '',
        facebook: '',
        instagram: '',
        first_image_footer: '',
        second_image_footer: '',
        contact_image: ''
    },
    showHeaderAndFooter: true
}

const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOME:
            return {
                ...state,
                data: action.payload[0],
                showHeaderAndFooter: true
            }
        case HIDDEN_HEADER:
            return {
                ...state,
                showHeaderAndFooter: !action.payload
            }
        default:
            return {...state}
    }
}

export default HomeReducer;