import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage"
import "./hero.scss";
// import Image from '../../../assets/images/scholarships/hero.jpg'

const Hero = ({ image }) => {
  return (
    <div className="scholarships">
      <BackgroundImage backgroundPath={image}>
        <div className="scholarships-hero"></div>
      </BackgroundImage>
    </div>
  )
}
export default Hero