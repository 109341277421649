import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Routes from './routes'
import Login from '../views/login/Login';
import Home from '../views/home/home';
import ColombiaHistory from '../views/colombiaHistory/colombiaHistory';
import EducativeOffer from '../views/educativeOffer/educativeOffer';
import Header from "../components/genericComponents/header/Header";
import Footer from "../components/genericComponents/footer/Footer";
import ContactUs from '../views/contactUs/contactUs';
import StudyInColombia from '../views/studyInColombia/studyInColombia';
import LearnSpanish from '../views/learnSpanish/learnSpanish';
import LiveInColombia from "../views/liveInColombia/liveInColombia";
import Scholarships from '../views/scholarships/scholarships';
import StudyAndWork from '../views/studyAndWork/studyAndWork';
import SingUp from "../views/signup/SignUp";
import Programs from '../views/programs/programs';
import { useSelector } from "react-redux";
import UniversityDetail from "../views/universityDetail/UniversityDetail";
import AfterStudy from "../views/afterStudy/AfterStudy";
import UnderConstruction from '../components/underConstruction/UnderConstruction';
import HigherEducationSystem from '../views/higherEducationSystem/higherEducationSystem';
import AncestralKnowledge from '../views/ancestralKnowledge/ancestralKnowledge';
import WhereToStart from '../views/whereToStart/whereToStart'
import Internship from '../views/internship/internship';
import UniversityCity from '../views/universityCity/universityCity';
import ColombianCulture from "../views/colombianCulture/ColombianCulture";
import ReactGA from 'react-ga';
import ProgramDetail from "../views/programDetail/ProgramDetail";

const Navigation = () => {
    ReactGA.initialize('G-GF7H986JM5');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const home = useSelector(store => store.home);

    return (
        <Router>
            {home && home.showHeaderAndFooter && <Header />}
            <Switch>
                <Route path={Routes.LOGIN}>
                    <Login />
                </Route>
                <Route path={Routes.SIGN_UP}>
                    <SingUp />
                </Route>
                <Route path={Routes.DISCOVER_COLOMBIA_HISTORY}>
                    <ColombiaHistory />
                </Route>
                <Route path={Routes.DISCOVER_COLOMBIA_ANCESTRAL}>
                    <AncestralKnowledge />
                </Route>
                <Route path={Routes.DISCOVER_COLOMBIA_CITIES}>
                    <UniversityCity />
                </Route>
                <Route path={Routes.EDUCATIVE_OFFER}>
                    <EducativeOffer />
                </Route>
                <Route path={Routes.PROGRAMS}>
                    <Programs />
                </Route>
                <Route path={Routes.CONTACT_US}>
                    <ContactUs />
                </Route>
                <Route path={Routes.STUDY_IN_COLOMBIA_REASONS}>
                    <StudyInColombia />
                </Route>
                <Route path={Routes.STUDY_IN_COLOMBIA_START}>
                    <WhereToStart />
                </Route>
                <Route path={Routes.STUDY_IN_COLOMBIA_LEARN}>
                    <LearnSpanish />
                </Route>
                <Route path={Routes.STUDY_IN_COLOMBIA_SUPERIOR}>
                    <HigherEducationSystem />
                </Route>
                <Route path={Routes.STUDY_IN_COLOMBIA_INTERNSHIP}>
                    <Internship />
                </Route>
                <Route path={Routes.LIVE_IN_COLOMBIA}>
                    <LiveInColombia />
                </Route>
                <Route path={Routes.SCHOLARSHIPS}>
                    <Scholarships />
                </Route>
                <Route path={Routes.STUDY_AND_WORK}>
                    <StudyAndWork />
                </Route>
                <Route path={Routes.AFTER_STUDY}>
                    <AfterStudy />
                </Route>
                <Route path={`${Routes.UNIVERSITY_DETAIL}/:id`}>
                    <UniversityDetail />
                </Route>
                <Route path={`${Routes.PROGRAM_DETAIL}/:program_id`}>
                    <ProgramDetail />
                </Route>
                <Route path={Routes.COLOMBIAN_CULTURE}>
                    <ColombianCulture/>
                </Route>
                <Route exact path={Routes.HOME}>
                    <Home />
                </Route>
                <Route path="*">
                    <UnderConstruction />
                </Route>
            </Switch>
            {home && home.showHeaderAndFooter && <Footer />}
        </Router>
    );
};

export default Navigation;
