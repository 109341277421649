import React from 'react'
import BackgroundImage from '../../genericComponents/backgroundImage/BackgroundImage'
// import Image from '../../../assets/images/studyInColombia/learnSpanish/hero.jpg'
import './hero.scss'

const Hero = ({ image }) => {
  return (<BackgroundImage backgroundPath={image}>{
    <div className="learn-spanish__hero">
    </div>
  }</BackgroundImage>)
}
export default Hero