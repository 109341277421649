import React from 'react';
import "./Gastronomy.scss"
import SliderYellowBox from "../../genericComponents/sliderYellowBox/SliderYellowBox";
import {useTranslation} from "react-i18next";

const Gastronomy = ({gastronomies}) => {
    const {t} = useTranslation()

    const gastronomyList = gastronomies.map(option => {
        return {...option, subtitle: `${option.order}. ${option.name}`, content: option.description}
    })
    return (
        <div>
            <div className="gastronomy">
                <div className="gastronomy__title">
                    <span>{t("discoverColombia:gastronomyTitle")}</span>
                </div>
                <div className="gastronomy__subtitle">
                    <span>{t("discoverColombia:gastronomySubtitle-1")}</span>
                    <span>{t("discoverColombia:gastronomySubtitle-2")}</span>
                </div>
                <div className="gastronomy__second-subtitle">
                    <span>{t("discoverColombia:gastronomySecondSubtitle")}</span>
                </div>
            </div>
            <SliderYellowBox data={gastronomyList} keyName={'advantages-list'} />
        </div>
    );
};

export default Gastronomy;
