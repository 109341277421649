import React from 'react';
import { useTranslation } from 'react-i18next';
import "./Article.scss"

const Article = (props) => {
    const { thumb, title, content, created_at, id } = props.data;
    const { i18n } = useTranslation()
    let date, lang, dateFormatted
    try {
        date = new Date(created_at)
        lang = i18n.language === 'es' ? 'es-CO' : 'en-US'
        dateFormatted = new Intl.DateTimeFormat(lang, { dateStyle: 'long' }).format(date)
    } catch (error) {
        console.log(error)
    }

    return (
        <div className={"article"} key={`article-${id}`}>
            <div className={"article__img"}>
                <img src={thumb} alt="Imagen artículo" width={"158"} height={"158"} />
            </div>
            <div className={"article__content"}>
                <div className={"article__content--title"}>
                    <span>{title}</span>
                </div>
                <div className={"article__content--text"} dangerouslySetInnerHTML={{ __html: content }}>
                </div>
                <div className={"article__content--date"}>
                    {Intl.DateTimeFormat && date && dateFormatted}
                </div>
            </div>

        </div>
    );
};

export default Article;
