import React from 'react';
import "./Peculiarities.scss"
import {useTranslation} from "react-i18next";

const Peculiarities = ({peculiarities}) => {
    const {t} = useTranslation()

    return (
        <div className="peculiarities">
            <div>
                <div className="peculiarities__title">
                    <span>{t("discoverColombia:peculiaritiesTitle")}</span>
                </div>
                <div className="peculiarities__subtitle">
                    <span>{t("discoverColombia:peculiaritiesSubtitle-1")}</span>
                    <span>{t("discoverColombia:peculiaritiesSubtitle-2")}</span>
                    <span>{t("discoverColombia:peculiaritiesSubtitle-3")}</span>
                </div>
                <div className="peculiarities__second-subtitle">
                    <span>{t("discoverColombia:peculiaritiesSecondSubtitle-1")}</span>
                    <span>{t("discoverColombia:peculiaritiesSecondSubtitle-2")}</span>
                    <span>{t("discoverColombia:peculiaritiesSecondSubtitle-3")}</span>
                </div>
            </div>
            <div className="peculiarities__list">
                {peculiarities.map(peculiarity =>
                    <div className="peculiarities__list--card">
                        <div className="peculiarities__card--img">
                            <img src={peculiarity.image} alt="Imagen de particularidades"/>
                        </div>
                        <div className="peculiarities__card">
                            <div className="peculiarities__card--title">
                                <span>{peculiarity.name}</span>
                            </div>
                            <div className="peculiarities__card--text">
                                <span>{peculiarity.description}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Peculiarities;
