import './city.scss'

const City = ({ name, description, image, latitude, longitude, changeCenter }) => {
  return (
    <article className="city-u" onClick={() => { changeCenter([latitude, longitude]) }}>
      <img className="city-u__image" src={image} alt="Imagen Ciudad Universitaria" />
      <div className="city-u__content">
        <p className="city-u__title">{name}</p>
        <p className="city-u__description">{description}</p>
      </div>
    </article>)
}
export default City