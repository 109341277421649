import './sectionHeader.scss'

const SectionHeader = ({ title, subtitle, content, full, invert, fullContent }) => {
  return (
    <>
      {subtitle &&
        <h3
          className={`section-header__subtitle 
          ${invert && 'section-header__subtitle--invert'}`}>{subtitle}</h3>
      }
      {title &&
        <h2 className={`section-header__title 
        ${full && 'section-header__title--full'} 
        ${content && 'section-header__title--no-margin'}
        ${invert && 'section-header__title--invert'}
        `}>{title}</h2>
      }
      { content
        &&
        <p className={`section-header__content ${invert && 'section-header__content--invert'}`}>{content}</p>
      }
      {
        fullContent
        && <div className="section-header__full-content" dangerouslySetInnerHTML={{ __html: fullContent }}></div>
      }
    </>
  )
}
export default SectionHeader