import React, {useEffect, useState} from 'react';
import HeroUniversity from "../../components/universityDetail/heroUniversity/HeroUniversity";
import ProgramInformation from "../../components/programInformation/ProgramInformation";
import {useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Loader from "../../components/genericComponents/loader/Loader";
import * as Types from "../../store/types";
import {ENDPOINTS} from "../../constants/endpoints";
import axios from "axios";

const initialState = {
    name: '',
    description: '',
    image: '',
    whatsapp_url: '',
    cost: '',
    start_date: '',
    city: {},
    program_type: {},
    study_area: {},
    modality: {},
    institution: {},
    registration_url: '',
    latitude: '',
    longitude: ''
}

const ProgramDetail = () => {
    const [state, setState] = useState(initialState);
    const {program_id} = useParams();
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({type: Types.HIDDEN_HEADER, payload: true})
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.EDUCATIVE_OFFER.PROGRAM.replace('id', program_id)}`)
            const {id, name, description, image, whatsapp_url, cost, start_date, city, program_type, study_area, modality, institution, registration_url, latitude, longitude} = data;
            setState({id, name, description, image, whatsapp_url, cost, start_date, city, program_type, study_area, modality, institution, registration_url, latitude, longitude})
        }

        fetchData().then(() => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
    }, [i18n.language])

    const urlTest = `${ENDPOINTS.BASE}/media/images_discover_colombia_principal/flag.jpg`
    return (
        <>
            {
                state.showLoading ?
                    <div className="without-home">
                        <Loader/>
                    </div> :
                    <>
                        <HeroUniversity title={state.name} image={urlTest}/>
                        {<ProgramInformation {...state} />}
                    </>
            }
        </>
    );
};

export default ProgramDetail;
