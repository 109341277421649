import React from "react";
import TimelineEvent from './timelineEvent/TimelineEvent';
import './timeline.scss';

const Timeline = ({ data }) => {
    return (
        <section
            className="timeline"
            style={{ gridTemplateRows: `repeat(${data.length},auto)` }}
        >
            <div className="timeline__line"/>
            <div className="timeline__empty"/>
            {data.map((event, index) => (
                <TimelineEvent key={`timeline-${index}`} {...event} invert={index % 2 !== 0} />
            ))}
        </section>
    );
};
export default Timeline;
