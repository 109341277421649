// Authentication
export const LOGIN = '/login';
export const SIGN_UP = '/sign-up';

// Dashboard
export const HOME = '/';

// Discover colombia - Colombia History
export const DISCOVER_COLOMBIA_HISTORY = '/discover-colombia/history';

// Discover colombia - Ancestral knowledge
export const DISCOVER_COLOMBIA_ANCESTRAL = '/discover-colombia/ancestral-knowledge';

// Discover colombia - University city
export const DISCOVER_COLOMBIA_CITIES = '/discover-colombia/cities';

// Colombian Culture
export const COLOMBIAN_CULTURE = '/discover-colombia/colombian-culture';


// Educative Offer - programs
export const EDUCATIVE_OFFER = '/educative-offer/programs';

// Educative Offer - Scholarships
export const SCHOLARSHIPS = '/educative-offer/scholarships';

// Programs results
export const PROGRAMS = '/programs'

// Contact us
export const CONTACT_US = '/contact';

// Study in Colombia - reason
export const STUDY_IN_COLOMBIA_REASONS = '/study-in-colombia/reasons'

// Study in Colombia - Where to start
export const STUDY_IN_COLOMBIA_START = '/study-in-colombia/where-to-start'

// Study in colombia - learn spanish
export const STUDY_IN_COLOMBIA_LEARN = '/study-in-colombia/learn'

// Study in Colombia - higher education system
export const STUDY_IN_COLOMBIA_SUPERIOR = '/study-in-colombia/higher-education-system'

// Study in Colombia - internship and volunteering
export const STUDY_IN_COLOMBIA_INTERNSHIP = '/study-in-colombia/internship-and-volunteering/'

// Live in Colombia - cost
export const LIVE_IN_COLOMBIA = '/live-in-colombia/cost';

// Live in Colombia - Study and work
export const STUDY_AND_WORK = '/live-in-colombia/study-and-work';

// After study
export const AFTER_STUDY = '/live-in-colombia/after-study';

// University
export const UNIVERSITY_DETAIL = '/university-detail';

// Program
export const PROGRAM_DETAIL = '/program-detail';
