import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import "./accessLink.scss"
import { AccessLinkOptions } from "./constants/AccessLinkOptions";
import AccessLinkOption from "./accessLinkOptions/AccessLinkOptions";

const AccessLink = ({ menuOpen }) => {
    const history = useHistory()
    const arrayList = [
        AccessLinkOptions.discoverColombia,
        AccessLinkOptions.studyInColombia,
        AccessLinkOptions.educativeOffer,
        AccessLinkOptions.liveInColombia,
        AccessLinkOptions.publications,
        AccessLinkOptions.contact
    ]
    const [linkText, setLinkText] = useState(arrayList);

    return (
        <>
            <div className={`link-list ${menuOpen && 'link-list--show'}`}>
                {linkText.map((selection, index) =>
                    <AccessLinkOption
                        key={`access-options-${index}`}
                        selection={selection}
                        indexLink={index}
                        active={history.location.pathname.match(selection.base)}
                    />
                )}
            </div>
        </>
    );
};

export default AccessLink;
