import {useState} from 'react'
import {useTranslation} from "react-i18next"
import BackgroundBanner from "../../genericComponents/backgroundBanner/BackgroundBanner"
import Modal from "../../genericComponents/modal/Modal";
import "./TaxResidentCertificate.scss"

const TaxResidentCertificate = ({content, image, contentModal}) => {
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }
    const close = () => {
        setShowModal(false)
    }

    return (
        <>
            <BackgroundBanner
                path={image}
                content={content}
                buttonText={t("common:seeMore")}
                onClick={() => openModal()}
                full
            />
            {showModal &&
            <div  className="tax-resident__modal">
                <Modal close={close}>
                    <div dangerouslySetInnerHTML={{__html: contentModal}}/>
                </Modal>
            </div>
            }
        </>
    )
}
export default TaxResidentCertificate