import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import GenericButton from "../../genericComponents/genericButton/GenericButton"
import City from "./city/City"
import './mosaic.scss'

const Mosaic = ({ cities }) => {
  const { t } = useTranslation()
  return (
    <section className="mosaic col-xs-12 col-md-10">
      <div className="mosaic__container">
        {cities[0] && <City data={cities[0]} />}
        {cities[1] && <City data={cities[1]} big />}
        {cities[2] && <City data={cities[2]} big />}
        {cities[3] && <City data={cities[3]} />}
      </div>
      <div className="mosaic__show-all">
        <Link to={{ pathname: '/programs' }}>
          <GenericButton text={t("educativeOffer:consultMore")} />
        </Link>
      </div>
    </section>)
}
export default Mosaic