import React from 'react';
import "./InformationUniversity.scss"
import Default from "../../../assets/images/default.jpg"

const InformationUniversity = ({name, acronym, address, phone_number, description, logo, whatsapp_url, city}) => {

    const goTo = () => window.open(whatsapp_url, '_blank', 'noopener,noreferrer');

    return (
        <div className="information-university">
            <div className="information-university__logo">
                <img src={logo? logo:Default} alt="Logo de Universidad" width={100}/>
            </div>
            <div className="information-university__name">{name}</div>
            <div className="information-university__address">{`${city?.name} - ${address}`}</div>
            <div className="information-university__phone_number">{phone_number}</div>
            <div className="information-university__whatsapp">
                <span onClick={() => goTo()}>
                    {whatsapp_url}
                </span>
            </div>
            <div className="information-university__description">{description}</div>
        </div>
    );
};

export default InformationUniversity;
