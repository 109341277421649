import React from 'react';
import "./ImageAndText.scss"

const ImageAndText = ({list}) => {
    return (
        <div className="image-and-text">
            {list.map((data, index) =>
                <div key={`imageAndText-${index}`} className="image-and-text__order">
                    <div className={index % 2 !== 0 ? "image-and-text__order--image img-order-odd" : "image-and-text__order--image img-order-pair"}>
                        <img src={data.img} alt="Imagen de testimonio"/>
                    </div>
                    <div className={index % 2 !== 0 ? "image-and-text__order--text text-order-odd" : "image-and-text__order--text text-order-pair"}>
                        <div className={"testimonial__text"} dangerouslySetInnerHTML={{__html: data.text}}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageAndText;
