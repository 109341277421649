import { ADD_FILTER_OPTIONS } from "../types";

const initialState = {
  programs: [],
  studyAreas: [],
  modalities: [],
  institutions: [],
  locations: [],
}

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER_OPTIONS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return { ...state }
  }
}

export default HomeReducer;