import { useState } from 'react'
import GenericButton from "../genericButton/GenericButton"
import SectionHeader from "../sectionHeader/SectionHeader"
import "./imageTextInterspersed.scss"
import Modal from "../modal/Modal";

const ImageTextInterspersed = ({
    title,
    subtitle,
    firstTitle,
    firstContent,
    firstImage,
    secondTitle,
    secondContent,
    secondImage,
    buttonText,
    url,
    gray,
    contentModal
}) => {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }
    const close = () => {
        setShowModal(false)
    }

    return (
        <>
            <section className={`image-text-interspersed ${gray && 'image-text-interspersed--gray'}`}>
                <div className="image-text-interspersed__wrapper">
                    <SectionHeader
                        subtitle={title}
                        title={subtitle}
                        full
                    />
                    <div className="image-text-interspersed__content">
                        <div className="image-text-interspersed__list">
                            {firstTitle &&
                                <h2 className="image-text-interspersed__title">{firstTitle}</h2>
                            }
                            <div dangerouslySetInnerHTML={{ __html: firstContent }} />
                        </div>
                        <img className="image-text-interspersed__image" src={firstImage} alt="Imagen intercalada" />
                    </div>
                    <div className="image-text-interspersed__content">
                        <img className="image-text-interspersed__image" src={secondImage} alt="Imagen intercalada" />
                        <div className="image-text-interspersed__list image-text-interspersed__list--last">
                            {secondTitle &&
                                <h2 className="image-text-interspersed__title">{firstTitle}</h2>
                            }
                            <div dangerouslySetInnerHTML={{ __html: secondContent }}>
                            </div>
                            {buttonText &&
                                <a href={url} target="_blank" rel="noreferrer" className="image-text-interspersed__btn">
                                    <GenericButton text={buttonText} onClick={() => openModal()} />
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </section>
            {showModal && contentModal &&
                <div className="tax-resident__modal">
                    <Modal close={close}>
                        <div dangerouslySetInnerHTML={{ __html: contentModal }} />
                    </Modal>
                </div>
            }
        </>
    )
}
export default ImageTextInterspersed