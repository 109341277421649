import React, {useState, useEffect} from 'react';
import HeroColombianCulture from "../../components/colombianCulture/HeroColombianCulture/HeroColombianCulture";
import Regions from "../../components/colombianCulture/Regions/Regions";
import RegionList from "../../components/colombianCulture/RegionList/RegionList";
import Gastronomy from "../../components/colombianCulture/Gastronomy/Gastronomy";
import Peculiarities from "../../components/colombianCulture/peculiarities/Peculiarities";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import * as Types from "../../store/types";
import axios from "axios";
import {ENDPOINTS} from "../../constants/endpoints";
import Loader from "../../components/genericComponents/loader/Loader";
import Characteristics from "../../components/colombianCulture/characteristics/Characteristics";
import FeatureArticles from "../../components/colombianCulture/featureArticles/FeaturesArticles";

const initialState = {
    characteristics: [],
    content: '',
    gastronomies: [],
    id: 0,
    peculiarities: [],
    principal_image: '',
    regions: '',
    title: '',
    showLoading: true
}

const ColombianCulture = () => {
    const [state, setState] = useState(initialState);
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({type: Types.HIDDEN_HEADER, payload: true})
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.DISCOVER_COLOMBIA.COLOMBIAN_CULTURE}`)
            setState(data[0])
        }

        fetchData().then(() => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
    }, [i18n.language])

    return (
        <>
            {
                state.showLoading ?
                    <div className="without-home">
                        <Loader/>
                    </div> :
                <>
                    <HeroColombianCulture  title={state.title} image={state.principal_image}/>
                    <Regions content={state.content}/>
                    <RegionList regions={state.regions}/>
                    <Gastronomy gastronomies={state.gastronomies}/>
                    <Peculiarities peculiarities={state.peculiarities}/>
                    <Characteristics characteristics={state.characteristics}/>
                    <FeatureArticles />
                </>
            }
        </>
    );
}

export default ColombianCulture;
