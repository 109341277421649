import React, {useEffect, useState} from 'react';
import HeaderHome from "../../components/homeComponents/headerHome/HeaderHome";
import HowToStart from "../../components/homeComponents/how-to-start/HowToStart";
import Reasons from "../../components/homeComponents/reasons/Reasons";
import AboutUs from "../../components/homeComponents/aboutUs/AboutUs";
import Testimonials from "../../components/homeComponents/testimonials/Testimonials";
import Articles from "../../components/homeComponents/articles/Articles";
import Sponsors from "../../components/homeComponents/sponsors/Sponsors";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/genericComponents/loader/Loader";
import "./home.scss"

const Home = () => {
    const home = useSelector(store => store.home)
    const [homeState, setHomeState] = useState(undefined)
    const dispatch = useDispatch()

    useEffect(() => {
        setHomeState(home.data);
    }, [home])

    return (
        <div>
            {home.showHeaderAndFooter && homeState && home.data.id !== 0?
                <>
                    <HeaderHome
                        image={homeState.principal_image}
                        title={homeState.principal_title}
                        subtitle={homeState.principal_subtitle}
                        video={homeState.video}
                        home
                    />
                    <HowToStart text={homeState.principal_text} steps={homeState.steps} />
                    <Reasons reasons={homeState.reasons} />
                    <AboutUs text={homeState.about_us_text} />
                    <Testimonials testimonies={homeState.testimonies} />
                    <Articles articles={homeState.posts} />
                    <Sponsors partners={homeState.partners} />
                </> :
                <div className="without-home">
                    <Loader />
                </div>
            }
        </div>
    )
};

export default Home;

