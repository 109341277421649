import Timeline from '../../genericComponents/timeline/Timeline';
import './history.scss';
import { useTranslation } from 'react-i18next';
import SliderArticles from '../../genericComponents/sliderArticles/SliderArticles';
import SectionHeader from '../../genericComponents/sectionHeader/SectionHeader';

const History = ({ principalText, timeline, articles }) => {
    const { t } = useTranslation();
    return (
        <>
            <section className="history-container">
                <div className="history-container__title" dangerouslySetInnerHTML={{ __html: principalText }}></div>
                <section className="history-container__timeline">
                    <Timeline data={timeline} />
                </section>
                <div className="history-container__explore">
                    <SectionHeader
                        subtitle={t('colombiaHistory:life')}
                        title={t('colombiaHistory:opportunities')}
                    />
                </div>
                <SliderArticles data={articles} />
            </section>
        </>
    );
};
export default History;
