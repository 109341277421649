import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnglishJson from "../en.json";
import SpanishJson from "../es.json";

const resources = {
    en: EnglishJson,
    es: SpanishJson
};

const defaultLang = localStorage.getItem('lang') || 'es'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ns: Object.keys(EnglishJson),
        resources,
        lng: defaultLang,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;