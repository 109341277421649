import React, { useEffect, useState } from 'react';
import AccessLink from "./accessLink/accessLink";
import Banner from "./banner/Banner";
import "./header.scss"
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getDataHome} from "../../../store/home/home.actions";

const Header = () => {
    const [fixed, setFixed] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    const withBackgroundSolid = ['/sign-up', '/login', '/contact-us', '/programs']

    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const [homeState, setHomeState] = useState(undefined)
    const home = useSelector(store => store.home)

    useEffect(() => {
        if(home.data.id === 0){
            dispatch(getDataHome(i18n.language === 'en' ? '/en' : ''));
            setHomeState(home.data);
        }
    }, [i18n.language])

    useEffect(() => {
        setHomeState(home.data);
    }, [home])

    useEffect(() => {
        function onScroll(e) {
            if (window.pageYOffset > 5) {
                if (!fixed) {
                    setFixed(true)
                }
            } else {
                if (fixed) {
                    setFixed(false)
                }
            }
        }
        document.addEventListener('scroll', onScroll, { passive: true })
        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [fixed])
    const onMenuButtonClick = () => {
        setMenuOpen(!menuOpen)
    }
    return (
        home?.data.id !== 0 &&
            <header
                className={`header  ${fixed || menuOpen || withBackgroundSolid.includes(window.location.pathname) ? 'header--solid' : ''}`}>
                <div className="header__container">
                    <Banner menuOpen={menuOpen} onMenuButtonClick={onMenuButtonClick}/>
                    <AccessLink menuOpen={menuOpen}/>
                </div>
            </header>
    );
};

export default Header;
