import React, { useState, useEffect } from 'react'
import Advantages from "../../components/educativeOffer/advantages/Advantages"
import Cities from "../../components/educativeOffer/cities/Cities"
import Scholarship from "../../components/educativeOffer/scholarship/Scholarship"
import Universities from "../../components/educativeOffer/universities/Universities"
import HeaderHome from "../../components/homeComponents/headerHome/HeaderHome"
import Image from '../../assets/images/studyInColombia/offer.jpg'
import Axios from 'axios'
import { ENDPOINTS } from '../../constants/endpoints'
import { useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux";
import * as Types from "../../store/types";
import Loader from "../../components/genericComponents/loader/Loader";

const initialState = {
    title: '',
    subtitle: '',
    video: '',
    advantages: [],
    showLoading: true,
    cities: []
}

const EducativeOffer = (props) => {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    useEffect(() => {
        const lang = i18n.language === 'es' ? '' : '/en'

        async function fetchData() {
            dispatch({ type: Types.HIDDEN_HEADER, payload: true })
            setState({ showLoading: true });
            const { data: main } = await Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.INDEX}`)

            setState({
                title: main.educative_offer.title,
                subtitle: main.educative_offer.subtitle,
                advantages: main.educative_offer.sliders,
                video: main.educative_offer.video,
                showLoading: false,
                cities: main.cities
            })
        }

        fetchData().then(() => dispatch({ type: Types.HIDDEN_HEADER, payload: false }))
    }, [i18n.language, dispatch])

    return (
        <>
            {
                state.showLoading ?
                    <div className="without-home">
                        <Loader />
                    </div> :
                    <>
                        <HeaderHome image={Image} video={state.video} title={state.title} subtitle={state.subtitle} />
                        <Universities />
                        <Cities cities={state.cities} />
                        {/* <Advantages advantages={state.advantages} /> */}
                        <Scholarship />
                    </>
            }
        </>
    )
};

export default EducativeOffer
