import { useState } from 'react'
import "./hero.scss"
import BackgroundImage from '../../genericComponents/backgroundImage/BackgroundImage'
import Image from '../../../assets/images/studyInColombia/higher/hero.jpg'
import Modal from '../../genericComponents/modal/Modal'

const Hero = ({ video }) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }
  const close = () => {
    setShowModal(false)
  }
  return (
    <>
      <BackgroundImage backgroundPath={Image}>
        <div className="higher-education__hero">
          <button className="btn-play" onClick={openModal}>
            <i class="ri-play-fill"></i>
          </button>
        </div>
      </BackgroundImage>
      {showModal && <Modal close={close}>
        <video src={video} controls autoplay="true" ></video>
      </Modal>}
    </>
  )
}
export default Hero