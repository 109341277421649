import React, {useEffect, useState} from 'react';
import { GENERIC_CONSTANTS } from "../../../../constants/generic-constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Types from "../../../../store/types";
import i18next from "i18next";
import "./Banner.scss"
import { useHistory } from "react-router-dom";
import * as Routes from "../../../../router/routes";

const Banner = ({ menuOpen, onMenuButtonClick }) => {
    const { ROLES } = GENERIC_CONSTANTS;
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const data = useSelector(store => store);
    const [showLanguages, setShowLanguages] = useState(false);

    const home = useSelector(store => store.home);

    const changeRole = (role) => {
        dispatch({
            type: Types.CHANGE_ROLE,
            payload: role
        });
    }

    const changeLanguage = (language) => {
        localStorage.setItem('lang', language)
        i18next.changeLanguage(language);
        setShowLanguages(false);
    }

    const gotTo = (url) => {
        history.push(url);
    }

    return (
        <div className="banner">
            <div className="logo">
                <img onClick={() => gotTo(Routes.HOME)} src={home.data.logo} alt="Logo" width={100} />
            </div>
            <div className={`roles ${menuOpen && 'roles--show'}`}>
                <div onClick={() => changeRole(ROLES.STUDENTS)}
                    className={`role border-left-role ${data.roles.role === ROLES.STUDENTS && 'role-selected'}`}>
                    {t("headerHome:students")}
                </div>
                {/*<div onClick={() => changeRole(ROLES.RESEARCHERS)}*/}
                <div
                    className={`role role__off ${data.roles.role === ROLES.RESEARCHERS && 'role-selected'}`}>
                    {t("headerHome:researchers")}
                </div>
                {/*<div onClick={() => changeRole(ROLES.INSTITUTIONS)}*/}
                <div
                    className={`role role__off border-right-role ${data.roles.role === ROLES.INSTITUTIONS && 'role-selected'}`}>
                    {t("headerHome:institutions")}
                </div>
            </div>
            <div className={"user"}>
                {/*<div className={"user__search"}>
                    <i className="ri-search-line" />
                </div>*/}
                <div id={"selectLanguage"} className="user__language-button">
                    {t("headerHome:language")}
                    {<div id={"optionsLanguages"} className="user__language-button--list">
                        <span onClick={() => changeLanguage('es')}>{t("headerHome:spanish")}</span>
                        <span onClick={() => changeLanguage('en')}>{t("headerHome:english")}</span>
                    </div>}
                </div>
                <div onClick={() => gotTo(Routes.LOGIN)} className="user__login">
                    <span>{t("headerHome:login")}</span>
                    <i className="ri-user-line" />
                </div>
                <button className={"menu-button"} onClick={onMenuButtonClick}>
                    <i className="ri-menu-3-line" />
                </button>
            </div>
        </div>
    );
};

export default Banner;
