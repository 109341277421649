import "./searcher.scss"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useFilterOptions from "../../../../hooks/useFilterOptions";
import GenericButton from "../../../genericComponents/genericButton/GenericButton";
import SearchSelect from "./searchSelect/SearchSelect";
import { setLocation, setInstitution, setModality, setProgramType, setStudyArea } from '../../../../store/filters/filters.actions'


const Searcher = () => {
    const filters = useSelector(store => store.filters)
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { programs, studyAreas, modalities, institutions, locations } = useFilterOptions()

    const changeProgram = (id) => {
        setProgramType(dispatch, id)
    }
    const changeStudyArea = (id) => {
        setStudyArea(dispatch, id)
    }
    const changeModality = (id) => {
        setModality(dispatch, id)
    }
    const changeInstitution = (id) => {
        setInstitution(dispatch, id)
    }
    const changeLocation = (id) => {
        setLocation(dispatch, id)
    }

    const search = () => {
        history.push('/programs')
    }
    return (
        <div className={"searcher-helper"}>
            <div className={"searcher"}>
                <div className={"searcher--message"}>
                    <span>{t("headerHome:searcherMessage")}</span>
                </div>
                <div className={"searcher--filter"}>
                    <SearchSelect
                        text={t("headerHome:typeOfProgram")}
                        icon={"ri-book-2-line"}
                        optionsList={programs}
                        current={filters.program_type}
                        setFilter={changeProgram}
                    />
                    <SearchSelect
                        text={t("headerHome:studyArea")}
                        icon={"ri-bookmark-3-line"}
                        optionsList={studyAreas}
                        current={filters.study_area}
                        setFilter={changeStudyArea}
                    />
                    <SearchSelect
                        text={t("headerHome:modality")}
                        icon={"ri-computer-line"}
                        optionsList={modalities}
                        setFilter={changeModality}
                        current={filters.modality}
                    />
                    <SearchSelect
                        text={t("headerHome:institution")}
                        icon={"ri-building-4-line"}
                        optionsList={institutions}
                        setFilter={changeInstitution}
                        current={filters.institution}
                    />
                    <SearchSelect
                        text={t("headerHome:location")}
                        icon={"ri-book-2-line"}
                        optionsList={locations}
                        setFilter={changeLocation}
                        current={filters.city}
                    />
                    <GenericButton
                        text={t("headerHome:search")}
                        iconLeft={'ri-search-line'}
                        onClick={search}
                    />
                </div>
            </div>
        </div>

    );
};

export default Searcher;
