import GenericButton from "../../../genericComponents/genericButton/GenericButton"
import "./result.scss"
import {useHistory} from "react-router-dom";
import * as Routes from "../../../../router/routes";
import Default from "../../../../assets/images/default.jpg"

const Result = ({ image, title, institution, programType, description, place, startDate, cost, whatsApp, open, registration, id }) => {
  const history = useHistory();

  const goToUniversityDetail = (id) => {
    history.push(`${Routes.UNIVERSITY_DETAIL}/${id}`)
  }

  const goToProgramDetail = (id) => {
    history.push(`${Routes.PROGRAM_DETAIL}/${id}`)
  }

  return (<div className={`result-card ${open && 'result-card--open'} `}>
    <img className="result-card__image" src={image? image: Default} alt="Imagen de resultado" />
    <div className="result-card__content">
      <p className="result-card__place-date">
        <span><i className="ri-map-pin-fill" />{place.name}, {place.state.country.name}</span>
        <span>Inicia: {startDate}</span>
      </p>
      <h3 className="result-card__title" onClick={() => goToProgramDetail(id)}>{title}
        <span className="result-card__price">{new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP" }).format(cost)}</span>
      </h3>
      <p className="result-card__type">{programType.name}</p>
      <p className="result-card__name" onClick={() => goToUniversityDetail(institution.id)}>{institution.name}</p>
      <p className="result-card__description">{description.substring(0, 200) + "..."}</p>
      <div className="result-card__actions">
        <a href={registration} target="_blank" rel="noreferrer">
          <GenericButton text="Inscríbete" />
        </a>
        {whatsApp !== '' && <a href={whatsApp} target="_blank" rel="noreferrer">
          <i className="ri-whatsapp-fill" />
        </a>}
      </div>
    </div>
  </div>)
}
export default Result
