import React, { useEffect, useState } from 'react';
import "./Login.scss"
import { useTranslation } from "react-i18next";
import useInput from "../../hooks/useInput";
import { useHistory } from "react-router-dom";
import * as Routes from "../../router/routes";
import axios from "axios";
import { ENDPOINTS } from "../../constants/endpoints";
import Modal from "../../components/genericComponents/modal/Modal";
import GenericButton from "../../components/genericComponents/genericButton/GenericButton";
import {useSelector} from "react-redux";

const Login = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [showButton, setShowButton] = useState(false);
    const [error, setError] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('genericErrors:generic');
    const password = useInput({ initialValue: '', required: true })
    const email = useInput({
        initialValue: '',
        required: true,
        regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    })
    const message = useInput({ initialValue: '' })

    const goTo = route => history.push(route);

    const validateInput = () => {
        setShowButton(Boolean(password.value) && Boolean(email.value))
    }

    const home = useSelector(store => store.home)

    const onSubmit = (e) => {
        e.preventDefault()
        if (email.valid && message.valid) {
            login();
        }
    }

    const login = () => {
        axios.post(`${ENDPOINTS.BASE}${ENDPOINTS.AUTHENTICATION.MAIN}${ENDPOINTS.AUTHENTICATION.LOGIN}/`,
            {
                "username": email.value,
                "password": password.value
            }).then(res => {
                if (res.status === 200) {
                    setError(false);
                    setOpenModal(true);
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    setErrorMessage("genericErrors:login");
                    setError(true);
                    setOpenModal(true);
                } else {
                    setErrorMessage("genericErrors:generic");
                    setError(true);
                    setOpenModal(true);
                }
            })
    }

    const closeModal = () => {
        setOpenModal(false)
        setError(false)
    }

    useEffect(() => {
        validateInput();
    }, [email.value, password.value])

    return (
        <>
            <div className="header-background-space" />
            <div className="login">
                <div className="login__img">
                    <img src={home.data.contact_image} alt="Imagen de Contacto" />
                    <div className="login__img--text">
                        <div className="title">
                            {t("signUp:colombia")}
                        </div>
                        <div className="subtitle">
                            <span>{t("signUp:signupAndStart")}</span>
                            <span>{t("signUp:experience")}</span>
                        </div>
                    </div>
                </div>
                <div className="login__form">
                    <div className="login__form--logo">
                        <img src={home.data.logo} alt="Logo del Sitio" width={200}/>
                    </div>
                    <div className="login__form--form">
                        <form className="form" onSubmit={onSubmit}>
                            <h5 className="login__title">{t("login:login")}</h5>
                            <input onChange={email.onChange} value={email.value} className="contact__input" type="email"
                                placeholder={t("login:email")} />
                            <input onChange={password.onChange} value={password.value} className="contact__input"
                                type="password"
                                placeholder={t("login:password")} />
                            <div className="login__forgotPassword">
                                <span onClick={() => goTo('/')}>{t('login:forgotPassword')}</span>
                            </div>
                            <button disabled={!showButton} className="login__button">{t("login:login")}</button>

                            <div className="login__sign-up">
                                <span>{t('login:new')}</span>
                                <span onClick={() => goTo(Routes.SIGN_UP)}>{t('login:newAccount')}</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {openModal &&
                <Modal close={closeModal} bottom={<GenericButton text={t("common:continue")} onClick={closeModal} />}>
                    {error ? (
                        <h3 className="contact__message">{t(errorMessage)}</h3>
                    ) :
                        (
                            <>
                                <h3 className="contact__message">{t("login:loginSuccess")}</h3>
                            </>
                        )
                    }
                </Modal>}
        </>
    );
};

export default Login;
