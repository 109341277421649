import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import "./Costs.scss"
import GenericButton from "../../genericComponents/genericButton/GenericButton";
import Modal from "../../genericComponents/modal/Modal";
import {Player} from "video-react";

const Cost = ({title, subtitle, content, costs, video}) => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [urlVideo, setUrlVideo] = useState('');

    const playVideo = (video) => {
        setUrlVideo(video);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrlVideo('')
        setShowModal(false)
    }

    return (
        <div className="class">
            <div className="class__title">{title}</div>
            <div className="class__subtitle">
                <span>{subtitle}</span>
            </div>
            <div className="class__text">
                <span dangerouslySetInnerHTML={{__html: content}}/>
            </div>

            <div className="class__list">
                {costs.map((cost, index) =>
                    <div key={`cost-live-${index}`} className="class__list--order">
                        <div className="class__list--icon">
                            <img src={cost.image} alt="Imagen de costos" />
                        </div>
                        <div className="class__list--title">
                            {cost.name}
                        </div>
                        <div className="class__list--text">
                            <span>{cost.description}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="class__button">
                <GenericButton onClick={() => playVideo(video)} text={t("liveInColombia:seeVideo")}/>
            </div>
            {showModal && <div className="testimonial__video">
                <Modal close={closeModal}>
                    <video src={video} controls autoPlay="true"></video>
                </Modal>
            </div>}
        </div>
    );
};

export default Cost;
