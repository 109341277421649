import React, {useEffect, useState} from 'react';
import HeroUniversity from "../../components/universityDetail/heroUniversity/HeroUniversity";
import InformationUniversity from "../../components/universityDetail/informationUniversity/InformationUniversity";
import {useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Loader from "../../components/genericComponents/loader/Loader";
import * as Types from "../../store/types";
import {ENDPOINTS} from "../../constants/endpoints";
import axios from "axios";

const initialState = {
    name: '',
    acronym: '',
    address: '',
    phone_number: '',
    description: '',
    logo: '',
    whatsapp_url: '',
    main_color: '',
    secondary_color: '',
    city: '',
    image: '',
    showLoading: true
}

const UniversityDetail = () => {
    const [state, setState] = useState(initialState);
    const {id} = useParams();
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({type: Types.HIDDEN_HEADER, payload: true})
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.INSTITUTIONS.MAIN.replace('id', id)}`)
            const {name, acronym, address, phone_number, description, logo, whatsapp_url, main_color, secondary_color, city} = data;
            setState({name, acronym, address, phone_number, description, logo, whatsapp_url, main_color, secondary_color, city})
        }

        fetchData().then(() => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
    }, [i18n.language])

    const urlTest = `${ENDPOINTS.BASE}/media/images_discover_colombia_principal/flag.jpg`
    return (
        <>
            {
                state.showLoading ?
                    <div className="without-home">
                        <Loader/>
                    </div> :
                    <>
                        <HeroUniversity title={state.name} image={urlTest}/>
                        <InformationUniversity {...state} />
                    </>
            }
        </>
    );
};

export default UniversityDetail;
