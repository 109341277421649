import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage"
import "./hero.scss"

const Hero = ({ title, image }) => {
  return (<BackgroundImage backgroundPath={image}>
    <div className="ancestral__hero">
      <h1 className="ancestral__title">{title}</h1>
    </div>
  </BackgroundImage>)
}
export default Hero