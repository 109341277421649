import { SET_CITIES, SET_PROGRAM_TYPE, SET_MODALITIES, SET_INSTITUTIONS, SET_STUDY_AREAS, CLEAR_FILTERS } from "../types";

const initialState = {
  program_type: null,
  study_area: null,
  modality: null,
  institution: null,
  city: null,
}

const FiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CITIES:
      return {
        ...state,
        city: action.payload
      }
    case SET_INSTITUTIONS:
      return {
        ...state,
        institution: action.payload
      }
    case SET_PROGRAM_TYPE:
      return {
        ...state,
        program_type: action.payload
      }
    case SET_MODALITIES:
      return {
        ...state,
        modality: action.payload
      }
    case SET_STUDY_AREAS:
      return {
        ...state,
        study_area: action.payload
      }
    case CLEAR_FILTERS:
      return {
        program_type: null,
        study_area: null,
        modality: null,
        institution: null,
        city: null,
      }
    default:
      return state
  }
}

export default FiltersReducer;