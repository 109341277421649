import { useState, useEffect, useRef } from 'react'
import "./select.scss";

const Select = ({ options, current, onChange, placeholder, invalid }) => {
  const [open, setOpen] = useState(false)
  const select = useRef(null)

  const toggleSelect = () => {
    setOpen(!open)
  }
  useEffect(() => {
    function onClick(e) {
      if (e.target !== select.current && open) {
        setOpen(false)
      }
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [open])
  const country = current ? options.filter(opt => opt.name === current)[0].name : placeholder
  return (
    <div ref={select} className={`select ${open ? 'select--active' : ''} ${invalid ? 'select--invalid' : ''}`} onClick={toggleSelect}>
      <span className={`select__current ${current ? 'select__current--selected' : ''}`}>{country} {open ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}</span>
      {open &&
        <ul className="select__options">
          {options?.map((opt, index) =>
            <li
              key={`select-option-${index}`}
              className={`select__option ${opt.name === current ? 'select__option--active' : ''}`}
              onClick={() => { onChange(opt.name) }}
            >{opt.name}</li>)}
        </ul>
      }
    </div>
  )
}
export default Select