import React from "react";
import { Provider } from 'react-redux';
import Navigation from "./router/Navigation";
import { store } from './store/store'
import 'remixicon/fonts/remixicon.css';
import "./App.scss"


function App(props) {

    return (
        <Provider store={store}>
            <div>
                <Navigation />
            </div>
        </Provider>
    );
}

export default App;
