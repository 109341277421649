import SectionHeader from "../sectionHeader/SectionHeader"
import "./backgroundBanner.scss"
import {useHistory} from "react-router-dom";
import * as Routes from "../../../router/routes";
import {PROGRAMS} from "../../../router/routes";


const BackgroundBanner = ({ title, subtitle, buttonText, path, content, safeContent, fullContent, full, onClick }) => {
  const history = useHistory();
  const goTo = route => history.push(route);

  return (
    <div className="background-banner" style={{ backgroundImage: `url(${path})` }}>
      {content ?
        <div dangerouslySetInnerHTML={{ __html: content }}></div> :
        <SectionHeader title={title} subtitle={subtitle} content={safeContent} fullContent={fullContent} full={full} invert />
      }
      {buttonText &&
        <button onClick={() => goTo(Routes.PROGRAMS)} className="background-banner__button">{buttonText}</button>
      }
    </div>)
}
export default BackgroundBanner