import React, { useState } from 'react';
import "./BoxInformationSimple.scss"
import Modal from "../modal/Modal";

const BoxInformationSimple = ({ img, title, text, content }) => {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <>
            <div className="box-information-simple" onClick={() => openModal()}>
                <div className="box-information-simple__img">
                    <img src={img} alt="Imagen de hospedaje"/>
                </div>
                <div className="box-information-simple__title">
                    <span>{title}</span>
                </div>
                <div className="box-information-simple__text">
                    <span>{text}</span>
                </div>
            </div>
            {showModal &&
                <Modal close={closeModal} full>
                    <div className="testimonial__video">
                        <div className="box-information-simple__modal" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </Modal>
            }
        </>
    );
}
    ;

export default BoxInformationSimple;
