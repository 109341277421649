import React from 'react';
import "./Travel.scss"

import SliderTravel from "./sliderTravel/SliderTravel";

const Travel = ({options, title, subtitle, description}) => {

    return (
        <div className="travel">
            <div className="travel__title">
                {title}
            </div>
            <div className="travel__subtitle">
                <span>{subtitle}</span>
            </div>
            <div className="travel__text">
                <span>{description}</span>
            </div>
            <div className="travel__list">
                <SliderTravel data={options} />
            </div>
        </div>
    );
};

export default Travel;
