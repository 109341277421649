import React from 'react';
import "./BoxInformationLocation.scss"

const BoxInformationLocation = ({img, location, title, text}) => {
    return (
        <div className="box-information-location">
            <div className="box-information-location__img">
                <img src={img} alt="Información"/>
            </div>
            <div className="box-information-location__location">
                <i className="ri-map-pin-fill"/>
                <span>{location}</span>
            </div>
            <div className="box-information-location__title">
                {title}
            </div>
            <div className="box-information-location__text">
                {text}
            </div>
        </div>
    );
};

export default BoxInformationLocation;
