import React from 'react';
import "./GenericButton.scss"

const GenericButton = ({ text, iconLeft, iconRight, onClick }) => {
    return (
        <button className={"generic-button"} onClick={onClick}>
            <span>
                {iconLeft && <i className={iconLeft} />}
                {text}
                {iconRight && <i className={iconRight} />}
            </span>
        </button>
    );
};

export default GenericButton;
