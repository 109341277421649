import React, {useEffect, useState} from 'react'
import axios from 'axios'
import HeroHistory from '../../components/colombiaHistoryComponents/heroHistory/HeroHistory';
import History from '../../components/colombiaHistoryComponents/history/History';
import {ENDPOINTS} from '../../constants/endpoints';
import {useTranslation} from 'react-i18next';
import Loader from "../../components/genericComponents/loader/Loader";
import * as Types from "../../store/types";
import {useDispatch} from "react-redux";

const initialState = {
    timeline: [],
    principalText: '',
    title: '',
    image: '',
    posts: [],
    showLoading: true
}
const ColombiaHistory = (props) => {
        const [state, setState] = useState(initialState)
        const dispatch = useDispatch()
        const {i18n} = useTranslation()
        useEffect(() => {
            async function fetchData() {
                dispatch({type: Types.HIDDEN_HEADER, payload: true})
                setState({showLoading: true});
                const LANGUAGE = i18n.language === 'en' ? '/en' : ''
                let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.DISCOVER_COLOMBIA.INDEX}`)
                data = data[0]
                let {data: posts} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.DISCOVER_COLOMBIA.POSTS}`)
                setState({
                    timeline: data.history_colombia_items,
                    principalText: data.principal_text,
                    title: data.title,
                    image: data.principal_image,
                    posts: posts.results.map(post => ({...post.post, order: post.order})),
                    showLoading: false
                })
            }

            fetchData().then( () => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
        }, [i18n.language])

        return (
            <>
                {state.showLoading ?
                    <div className="without-home">
                        <Loader/>
                    </div> :
                    <>
                        <HeroHistory title={state.title} image={state.image}/>
                        <History timeline={state.timeline} title={state.title} principalText={state.principalText}
                                 articles={state.posts}/>
                    </>
                }
            </>
        );
    }
;
export default ColombiaHistory;
