import { useState, useEffect, useRef, useCallback } from 'react'
import useSwipe from '../../../hooks/useSwipe'
import GenericButton from '../genericButton/GenericButton'
import './sliderYellowBox.scss'

const SliderYellowBox = ({ data, numbers, keyName }) => {
  const [current, setCurrent] = useState(0)
  const [animate, setAnimate] = useState(false)
  const element = useRef(null)

  const { id, title, subtitle, image, content, textButton, onClick } = data[current]

  const changeTo = (index) => {
    setAnimate(false)
    setCurrent(index)

    setTimeout(() => {
      setAnimate(false)
    }, 900)
  }

  const onSwipeLeft = useCallback(() => {
    setAnimate(false)
    setCurrent((state) => state < data.length - 1 ? state + 1 : state)
    setTimeout(() => {
      setAnimate(false)
    }, 900)
  }, [data])

  const onSwipeRight = useCallback(() => {
    setAnimate(false)
    setCurrent((state) => state > 0 ? state - 1 : state)
    setTimeout(() => {
      setAnimate(false)
    }, 900)
  }, [])

  useSwipe(element, onSwipeLeft, onSwipeRight)


  useEffect(() => {
    setAnimate(true)
  }, [current])

  return (
    <div className="slider-yellow-box" ref={element}>
      <div className="slider-yellow-box__container">
        <img className={`slider-yellow-box__image ${animate && 'fadeFromRight'}`} src={image} alt="Imagen Slider" />
        <div className={`slider-yellow-box__content ${animate && 'fadeFromLeft'}`}>
          {numbers &&
            <h2 className="slider-yellow-box__title">{numbers < 10 ? `0${current + 1}` : current}.</h2>
          }
          <h2 className="slider-yellow-box__title">{title}</h2>
          <h3 className="slider-yellow-box__subtitle">{subtitle}</h3>
          <p className="slider-yellow-box__text">{content}</p>
        </div>
        <div className={`slider-yellow-box__button ${animate && 'fadeFromBottom'}`}>
          {textButton &&
            <div onClick={() => onClick(data[current])}>
              <GenericButton text={textButton} iconRight="ri-arrow-right-line" />
            </div>
          }
        </div>
      </div>
      <div className="slider-yellow-box__dots">
        {data.map((__, index) =>
          <div
            key={`${keyName}-${index}`}
            className={`slider-yellow-box__dot ${current === index && 'slider-yellow-box__dot--active'}`}
            onClick={() => changeTo(index)}>
          </div>
        )}
      </div>
    </div >)
}
export default SliderYellowBox