export const ENDPOINTS = {
    //BASE: 'https://gocolcert.mineducacion.gov.co/api',
    BASE: 'https://gocolombia.edu.co/api',
    //BASE: 'http://localhost:8000',
    AUTHENTICATION: {
        MAIN: '/auth',
        LOGIN: '/login',
        LOGOUT: '/logout',
        SIGN_UP: '/signup',
        USERS: '/users'
    },
    HOME: {
        MAIN: '/home'
    },
    INSTITUTIONS: {
        MAIN: '/institutions/id'
    },
    LIVE_IN_COLOMBIA: {
        STUDY_AND_WORK: '/live-in-colombia/study-and-work-in-colombia/',
        MAIN: '/live-in-colombia',
        FINISH_STUDIES: '/what-to-do-when-finish-studies/',
        LIFE_IN_COLOMBIA: '/live-in-colombia/life-in-colombia/',
        POST: '/live-in-colombia/posts/'

    },
    STUDY_IN_COLOMBIA: {
        REASONS: '/study-in-colombia/',
        LEARN: '/study-in-colombia/learn-spanish/',
        POSTS: '/study-in-colombia/posts/',
        HIGHER: '/study-in-colombia/higher-education-system/',
        START: '/study-in-colombia/where-to-start/',
        INTERNSHIP: '/study-in-colombia/internship-and-volunteering/'
    },
    DISCOVER_COLOMBIA: {
        INDEX: '/discover-colombia',
        POSTS: '/discover-colombia/posts/',
        ANCESTRAL: '/discover-colombia/ancestral-knowledge/',
        CITIES: '/discover-colombia/university-city-section/',
        COLOMBIAN_CULTURE: '/discover-colombia/colombian-culture/',
        POST: '/discover-colombia/posts/'
    },
    COMMON: {
        CITIES: '/common/cities/'
    },
    EDUCATIVE_OFFER: {
        INDEX: '/educative-offer/',
        PROGRAM_TYPES: '/educative-offer/program-types/',
        STUDY_AREAS: '/educative-offer/study-areas/',
        MODALITIES: '/educative-offer/modalities/',
        INSTITUTIONS: '/educative-offer/institutions/',
        SCHOLARSHIP_SECTION: '/educative-offer/scholarship-section/',
        SPECIAL_CORPORATION_PROGRAMS: '/educative-offer/special-cooperation-programs/',
        SCHOLARSHIP_FOR_FOREIGNERS: '/educative-offer/scholarships-for-foreigners/',
        PROGRAMS: '/educative-offer/programs/',
        PROGRAM: '/educative-offer/programs/id',
    }
}
