import { useState } from 'react'
import Slick from 'react-slick';
import BackgroundBanner from '../../genericComponents/backgroundBanner/BackgroundBanner';
import '../../../styles/slick.min.css';
import './slider.scss';
import Modal from '../../genericComponents/modal/Modal';

const Slider = ({ data }) => {
  const [open, setOpen] = useState(false)
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  };
  const closeModal = () => {
    setOpen(false)
  }
  const openModal = () => {
    setOpen(true)
  }
  return (
    <div className="higher-slider">
      {data.length > 0 ? (
        <Slick  {...settings}>
          {
            data.map((item, index) => (
              <BackgroundBanner
                key={index}
                path={item.image}
                content={item.content}
                onClick={openModal}
                buttonText={item.button_text}
              />
            ))
          }
        </Slick>
      ) : <div></div>
      }
      {
        open && <Modal close={closeModal} full>
          <div dangerouslySetInnerHTML={{ __html: '<h1>Pendiente</h1>' }}></div>
        </Modal>
      }
    </div>
  )
};
export default Slider;
