import React from 'react';
import "./HowToStart.scss"
import { useTranslation } from "react-i18next";
import GenericButton from "../../genericComponents/genericButton/GenericButton";
import * as Routes from "../../../router/routes";
import {useHistory} from "react-router-dom";

const HowToStart = ({ text, steps }) => {
    const { t } = useTranslation();

    const history = useHistory();
    const goTo = route => history.push(route);

    return (
        <div className={"how-to-start"}>
            <div className={"how-to-start__title"}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div className={"how-to-start__list"}>
                {steps.map((option, index) =>
                    <div key={`how-to-start${index}`} className={"how-to-start__list--step"}>
                        <div className={"how-to-start__icon"}>
                            <img src={option.icon} alt="Icono"/>
                        </div>
                        <span key={`start-${index}`}>{option.name}</span>
                    </div>
                )}
            </div>
            <div className={"how-to-start__message"}>
                <span>{t("howToStart:message")}</span>
            </div>
            <div className={"how-to-start__button"}>
                <GenericButton text={t("howToStart:iWant")} onClick={() => goTo(Routes.STUDY_IN_COLOMBIA_START)}/>
            </div>
        </div>
    );
}

export default HowToStart;