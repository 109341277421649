import React, {useState} from 'react';
import Slider from 'react-slick';
import "./Testimonial.scss";
import Modal from "../../../genericComponents/modal/Modal";

const Testimonial = ({data}) => {
    const [showModal, setShowModal] = useState(false);
    const [urlVideo, setUrlVideo] = useState('');
    const NextArrow = ({currentSlide, slideCount, ...props}) => (
        <div {...props}>
            <div className={"testimonial__right"}>
                <i className="ri-arrow-right-s-line"/>
            </div>
        </div>
    );

    const PrevArrow = ({currentSlide, slideCount, ...props}) => (
        <div {...props}>
            <div className={"testimonial__left"}>
                <i className="ri-arrow-left-s-line"/>
            </div>
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>
    };

    const playVideo = (video) => {
        setUrlVideo(video);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrlVideo('')
        setShowModal(false)
    }

    return (
        <>
            <div className="slick-slider-testimonial">
                <Slider {...settings}>
                    {data ? data.map((testimonial, index) => {
                        const {video, title, country, content, image} = testimonial;
                        return <div key={`testimonial-${index}`} className={"testimonial"}>
                            <div className={"testimonial__img"}>
                                <img src={image} alt="Imagen de testimonio" />
                                <div className={"testimonial__play-icon"} onClick={() => playVideo(video)}>
                                    <i className="ri-play-fill"/>
                                </div>
                            </div>
                            <div className={"testimonial__name"}>{title}</div>
                            <div className={"testimonial__country"}>{country.name}</div>
                            <div className={"testimonial__text"} dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                    }) : <></>}
                </Slider>
            </div>
            {showModal && <div className="testimonial__video">
                <Modal close={closeModal}>
                    <video src={urlVideo} controls autoPlay="true"></video>
                </Modal>
            </div>}
        </>
    );
};

export default Testimonial;
