import "./form.scss"
import { useState, useEffect } from 'react'
import Select from "./select/Select"
import useInput from "../../../hooks/useInput"
import { useTranslation } from "react-i18next"
import axios from 'axios'
import { ENDPOINTS } from '../../../constants/endpoints'
import Modal from "../../genericComponents/modal/Modal"
import GenericButton from "../../genericComponents/genericButton/GenericButton"
import {useSelector} from "react-redux";

const Form = (props) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState(false)
  const [options, setOptions] = useState([])
  const [nationality, setNationality] = useState(null)
  const [country, setCountry] = useState(null)
  const [showErrorCountry, setShowErrorCountry] = useState(false)
  const [showErrorNationality, setShowErrorNationality] = useState(false)
  const name = useInput({ initialValue: '', required: true })
  const email = useInput({ initialValue: '', required: true, regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })
  const message = useInput({ initialValue: '', required: true })

  const home = useSelector(store => store.home)

  useEffect(() => {
    axios.get(`${ENDPOINTS.BASE}/common/countries`).then(({ data }) =>
      setOptions(data.results)
    )
  }, [])

  const changeCountry = (value) => {
    setCountry(value)
  }

  const changeNationality = (value) => {
    setNationality(value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (name.valid && email.valid && message.valid && country !== '' && nationality !== '') {
      axios.post(`${ENDPOINTS.BASE}/common/contact/`,
        {
          name: name.value,
          email: email.value,
          nationality: nationality,
          country: country,
          message: message.value
        }).then(res => {
          if (res.status === 200) {
            name.reset()
            email.reset()
            message.reset()
            setCountry(null)
            setNationality(null)
            setOpenModal(true)
          }
        }).catch(error => {
          console.error(error)
          setError(true)
          setOpenModal(true)
        })
    } else {
      name.updateShowError()
      email.updateShowError()
      message.updateShowError()
      if (!country) {
        setShowErrorCountry(true)
      }
      if (!nationality) {
        setShowErrorNationality(true)
      }
    }
  }

  const closeModal = () => {
    setOpenModal(false)
    setError(false)
  }

  return (
    <>
      <div className="header-background-space" />
      <section className="contact">
        <img className="contact__image" src={home.data.contact_image} alt="Imagen de contacto" />
        <form className="contact__form" onSubmit={onSubmit}>
          <h5 className="contact__us">{t("contactUs:contactUs")}</h5>
          <h4 className="contact__question">{t("contactUs:anyQuestion")}</h4>
          <p className="contact__instructions">{t("contactUs:fillTheForm")}</p>
          <input
            onChange={name.onChange}
            value={name.value}
            className={`contact__input ${!name.valid && name.showError ? 'contact__input--invalid' : ''}`}
            type="text"
            placeholder={t("contactUs:name")}
          />
          <input
            onChange={email.onChange}
            value={email.value}
            className={`contact__input ${!email.valid && email.showError ? 'contact__input--invalid' : ''}`}
            type="email"
            placeholder={t("contactUs:email")}
          />
          <Select
            current={nationality}
            options={options}
            placeholder={t("contactUs:nationality")}
            onChange={changeNationality}
            invalid={!nationality && showErrorNationality}
          />
          <Select
              current={country}
              options={options}
              placeholder={t("contactUs:country")}
              onChange={changeCountry}
              invalid={!country && showErrorCountry}
          />
          <textarea
            onChange={message.onChange}
            value={message.value}
            className={`contact__input contact__input--textarea ${!message.valid && message.showError ? 'contact__input--invalid' : ''}`}
            placeholder={t("contactUs:message")} />
          <button className="contact__button">{t("contactUs:send")}</button>
        </form>
      </section>
      { openModal &&
        <Modal close={closeModal} bottom={<GenericButton text={t("common:continue")} onClick={closeModal} />}>
          {error ? (
            <h3 className="contact__message">{t("contactUs:error")}</h3>
          ) :
            (
              <>
                <h3 className="contact__message">{t("contactUs:successfulRegister")}</h3>
                <p>{t("contactUs:soon")}</p>
              </>
            )
          }
        </Modal>}
    </>
  )
}
export default Form