import { Link } from 'react-router-dom'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { ENDPOINTS } from '../../../constants/endpoints'
import CommunicationCard from "../../genericComponents/communicationCard/CommunicationCard"
import GenericButton from "../../genericComponents/genericButton/GenericButton"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import "./universities.scss"
import * as Routes from "../../../router/routes";

const Universities = (props) => {
  const { t, i18n } = useTranslation()
  const [universities, setUniversities] = useState([])

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'
    async function fetchData() {
      const { data } = await Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.PROGRAMS}/?limit=8`)
      setUniversities(data.results)
    }
    fetchData()
  }, [i18n.language])

  return (<section className="university-offer col-xs-12 col-md-10">
    <SectionHeader title={t("educativeOffer:educationInColombia")} subtitle={t("educativeOffer:educativeOffer")} />
    <div className="university-offer__container">
      {universities.map((university, index) => (
        <CommunicationCard
          key={`university-${index}`}
          {...university}
          id={university.institution.id}
          title={university.institution.name}
          place={`${university.city.name}, ${university.city.state.country.name}`}
          src={university.image}
          component={
            <a className="communication-link"
              href={university.whatsapp_url}
              target="_blank"
              rel="noreferrer">{t("common:seeMore")}
            </a>}
          whatsApp
        />
      ))}
    </div>
    <div className="university-offer__button">
      <Link to={{ pathname: '/programs' }}>
        <GenericButton text={t("educativeOffer:citiesButton")} />
      </Link>
    </div>
  </section>)
}
export default Universities
