import "./bogota.scss"

const Bogota = ({ title, subtitle, content, image }) => {
  return (<section className="bogota">
    <div className="bogota__wrapper">
      <img className="bogota__image" src={image} alt="Imagen de bogotá" />
      <div className="bogota__content">
        <h3 className="bogota__subtitle">{title}</h3>
        <h2 className="bogota__title">{subtitle}</h2>
        <p className="bogota__description" dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    </div>
  </section>)
}
export default Bogota