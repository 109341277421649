import React, { useEffect, useState } from 'react';
import "./SignUp.scss"
import { useTranslation } from "react-i18next";
import Img from '../../assets/images/contactUs/woman.jpg'
import Select from "../../components/contactUs/form/select/Select";
import useInput from "../../hooks/useInput";
import Logo from '../../assets/images/footer/logo.png'
import * as Routes from "../../router/routes";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ENDPOINTS } from "../../constants/endpoints";
import Modal from "../../components/genericComponents/modal/Modal";
import GenericButton from "../../components/genericComponents/genericButton/GenericButton";

const SingUp = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [type, setType] = useState(null);
    const [error, setError] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [check, setCheck] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState('genericErrors:generic');
    const name = useInput({ initialValue: '', required: true })
    const surname = useInput({ initialValue: '', required: true })
    const password = useInput({ initialValue: '', required: true })
    const passwordRecovery = useInput({ initialValue: '', required: true })
    const email = useInput({
        initialValue: '',
        required: true,
        regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    })
    const options = [
        {
            value: 1,
            name: t("signUp:student")
        },
        {
            value: 2,
            name: t("signUp:teacher")
        },
        {
            value: 3,
            name: t("signUp:institution")
        }
    ]
    const changeType = (value) => setType(value);

    const goTo = route => history.push(route);

    const handleCheckChange = () => {
        setCheck(!check);
        validateInput();
    }

    const validateInput = () => {
        setShowButton(Boolean(name.value) &&
            Boolean(surname.value) &&
            Boolean(type) &&
            Boolean(password.value) &&
            Boolean(passwordRecovery.value) &&
            password.value === passwordRecovery.value &&
            Boolean(email.value) &&
            check)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (name.valid &&
            surname.valid &&
            password.valid &&
            email.valid &&
            check && type &&
            password.value === passwordRecovery.value) {
            createNewUser();
        }
    }

    const closeModal = () => {
        setOpenModal(false)
        setError(false)
    }

    const createNewUser = () => {
        axios.post(`${ENDPOINTS.BASE}${ENDPOINTS.AUTHENTICATION.USERS}${ENDPOINTS.AUTHENTICATION.SIGN_UP}/`,
            {
                "first_name": name.value,
                "last_name": surname.value,
                "email": email.value,
                "type": type.toUpperCase(),
                "password": password.value,
                "password_confirmation": passwordRecovery.value
            }).then(res => {
                if (res.status === 201) {
                    setError(false);
                    setOpenModal(true);
                }
            }).catch(error => {
                setError(true);
                if (error.response.data && error.response.data.password) {
                    setErrorMessage("genericErrors:characters")
                } else if (error.response.data && error.response.data.non_field_errors) {
                    setErrorMessage("genericErrors:insecurePassword")
                } else {
                    setErrorMessage("genericErrors:generic")
                }
                setOpenModal(true);
            })
    }

    useEffect(() => {
        validateInput();
    }, [check, name.value, surname.value, type, password.value, passwordRecovery.value, email.value])

    return (
        <>
            <div className="header-background-space" />
            <div className="sign-up">
                <div className="sign-up__img">
                    <img src={Img} alt="Imagen de contacto" />
                    <div className="sign-up__img--text">
                        <div className="title">
                            {t("signUp:colombia")}
                        </div>
                        <div className="subtitle">
                            <span>{t("signUp:signupAndStart")}</span>
                            <span>{t("signUp:experience")}</span>
                        </div>
                    </div>
                </div>
                <div className="sign-up__form">
                    <div className="sign-up__form--arrow">

                    </div>
                    <div className="sign-up__form--logo">
                        <img src={Logo} alt="logo-img" />
                    </div>
                    <div className="sign-up__form--form">
                        <form className="form" onSubmit={onSubmit}>
                            <h5 className="sign-up__title">{t("signUp:signup")}</h5>
                            <input onChange={name.onChange} value={name.value} className="contact__input" type="text"
                                placeholder={t("signUp:firstName")} />
                            <input onChange={surname.onChange} value={surname.value} className="contact__input"
                                type="text"
                                placeholder={t("signUp:lastName")} />
                            <input onChange={email.onChange} value={email.value} className="contact__input" type="email"
                                placeholder={t("contactUs:email")} />
                            <div className="sign-up__select">
                                <Select current={type} options={options} placeholder={t("signUp:type")}
                                    onChange={changeType} />
                            </div>
                            <input onChange={password.onChange} value={password.value} className="contact__input"
                                type="password"
                                placeholder={t("signUp:password")} />
                            <input onChange={passwordRecovery.onChange} value={passwordRecovery.value}
                                className="contact__input"
                                type="password"
                                placeholder={t("signUp:passwordConfirm")} />
                            <div className="sign-up__form--terms">
                                <input type="checkbox"
                                    className="sign-up__checkbox"
                                    checked={check}
                                    onChange={() => handleCheckChange()} />
                                <span>{t('signUp:read')} <u
                                    onClick={() => goTo('/')}>{t('signUp:terms')}</u>
                                </span>
                            </div>
                            <button disabled={!showButton} className="sign-up__button">{t("signUp:button")}</button>

                            <div className="sign-up__login">
                                <span>{t('signUp:hasAccount')}</span>
                                <span onClick={() => goTo(Routes.LOGIN)}>{t('signUp:login')}</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { openModal &&
                <Modal close={closeModal} bottom={<GenericButton text={t("common:continue")} onClick={closeModal} />}>
                    {error ? (
                        <h3 className="contact__message">{t(errorMessage)}</h3>
                    ) :
                        (
                            <>
                                <h3 className="contact__message">{t("signUp:created")}</h3>
                            </>
                        )
                    }
                </Modal>}
        </>
    );
};

export default SingUp;
