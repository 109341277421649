import { useState } from 'react'
import { useTranslation } from "react-i18next";
import GenericButton from "../../genericComponents/genericButton/GenericButton";
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader";
import "./wantToWork.scss";
import Modal from "../../genericComponents/modal/Modal";

const WantToWork = ({ title, subtitle, content, contentModal }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }
    const close = () => {
        setShowModal(false)
    }

    return (
        <>
            <section className="want-to-work">
                <SectionHeader subtitle={title} title={subtitle} />
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <GenericButton text={t("common:seeMore")} onClick={() => openModal()} />
            </section>
            {showModal &&
                <Modal close={close} full>
                    <div className="modal-overflow" dangerouslySetInnerHTML={{ __html: contentModal }} />
                </Modal>
            }
        </>
    )
}
export default WantToWork