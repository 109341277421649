import GenericButton from "../../genericComponents/genericButton/GenericButton"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import './rankings.scss'

const Rankings = ({ title, subtitle, fullContent, buttonText, url }) => {
  return (
    <section className="rankings">
      <SectionHeader title={title} subtitle={subtitle} fullContent={fullContent} />
      <a className="rankings__button" href={url} target="_blank" rel="noreferrer">
        <GenericButton text={buttonText} />
      </a>
    </section>)
}
export default Rankings