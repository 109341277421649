import CommunicationCard from "../../genericComponents/communicationCard/CommunicationCard"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import "./bestSpanish.scss"

const BestSpanish = ({ title, subtitle, content, placesToLearn }) => {
  return (
    <section className="best-spanish">
      <SectionHeader
        subtitle={subtitle}
        title={title}
        content={content}
      />
      <div className="best-spanish__scholarships">
        {placesToLearn.map((place) => (
          <CommunicationCard
            key={place.id}
            place={`${place.city.name}, ${place.city.state.country.name}`}
            title={place.title}
            description={place.content}
            src={place.image}
            fit="contain"
          />
        ))}
      </div>
    </section>)
}
export default BestSpanish