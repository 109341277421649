import CardWithModal from "../../genericComponents/cardWithModal/Card"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import "./requirements.scss"

const Requirements = ({ title, subtitle, data }) => {
  return (
    <section className="requirements">
      <SectionHeader title={title} subtitle={subtitle} />
      <div className="requirements__list">
        {data.map((requirement) => <CardWithModal
          key={requirement.id}
          title={requirement.name}
          summary={requirement.content}
          image={requirement.image}
        />)}
      </div>
    </section>)
}
export default Requirements