import React from 'react';
import { useTranslation } from "react-i18next";
import './footer.scss'
import { useHistory } from "react-router-dom";
import {useSelector} from "react-redux";

const Footer = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const year = new Date().getFullYear();

    const titles = {
        menu: t('footer:menu'),
        contactUs: t('footer:contact'),
        followUs: t('footer:followUs'),
    }
    const menuText = [
        { text: t('footer:discoverColombia'), route: '/' },
        { text: t('footer:studyInColombia'), route: '/' },
        { text: t('footer:investigationAndInnovation'), route: '/' },
        { text: t('footer:educativeOffer'), route: '/' },
        { text: t('footer:liveInColombia'), route: '/' },
        { text: t('footer:resources'), route: '/' },
    ]

    const home = useSelector(store => store.home)

    const contactText = [
        home.data.phone,
        home.data.email,
        home.data.address,
        home.data.city,
    ]

    const goTo = route => history.push(route)

    return ( home?.data.id !== 0 &&
        <div className="footer">
            <div className={"logos"}>
                <div className={"logos__logo"}>
                    <img src={home.data.logo} alt="Logo" width={200}/>
                </div>
                <div className={"logos__min"}>
                    <img src={home.data.first_image_footer} alt="Logo del Ministerio" />
                    <img src={home.data.second_image_footer} alt="Logo de iniciativa Colombia" />
                </div>
            </div>
            <div className="menu">
                <div className={"title"}>
                    <span>{titles.menu}</span>
                </div>
                <div className={"text-list text-list__cursor"}>
                    {
                        menuText.map((menu, index) =>
                            <span onClick={() => goTo(menu.route)} key={`menu-${index}`}>{menu.text}</span>
                        )}
                </div>
            </div>
            <div className="contact-us">
                <div className={"title"}>
                    <span>{titles.contactUs}</span>
                </div>
                <div className={"text-list"}>
                    {contactText.map((text, index) => <span key={`contact-${index}`}>{text}</span>)}
                </div>
            </div>
            {(home.data.facebook || home.data.twitter || home.data.instagram) && (
            <div className={"follow-us"}>
                <div className={"title"}>
                    <span>{titles.followUs}</span>
                </div>
                <div className={"follow-us__icon-list"}>
                    {home.data.facebook && (<a target="_blank" rel="noreferrer" href={home.data.facebook}><i className="ri-facebook-fill" /></a>)}
                    {home.data.twitter && (<a target="_blank" rel="noreferrer" href={home.data.twitter}><i className="ri-twitter-fill" /></a>)}
                    {home.data.instagram && (<a target="_blank" rel="noreferrer" href={home.data.instagram}><i className="ri-instagram-fill" /></a>)}
                </div>
            </div>
            )}
            <div className={"copyright"}>
                <span>©Copyright {year} - Go Colombia</span>
                <span><a target='_blank' href="https://www.mineducacion.gov.co/1759/w3-article-353715.html?_noredirect=1">{t("footer:data")}</a></span>
            </div>
        </div>
    );
}

export default Footer;