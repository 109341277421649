import axios from 'axios';
import * as Types from '../types'
import { ENDPOINTS } from '../../constants/endpoints'

export const getFilterOptions = async (dispatch, language) => {
  try {
    const lang = language === 'es' ? '' : '/en'
    const [programs, studyAreas, modalities, institutions, cities] = await axios.all([
      axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.PROGRAM_TYPES}`),
      axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.STUDY_AREAS}`),
      axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.MODALITIES}`),
      axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.INSTITUTIONS}`),
      axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.COMMON.CITIES}`),
    ])
    dispatch({
      type: Types.ADD_FILTER_OPTIONS,
      payload: {
        programs: programs.data.results,
        studyAreas: studyAreas.data.results,
        modalities: modalities.data.results,
        institutions: institutions.data.results,
        locations: cities.data.results
      }
    })
  } catch (error) {
    console.error(error);
  }
}