import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"
import { getFilterOptions } from "../store/options/options.actions"

const useFilterOptions = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const options = useSelector(state => state.options)

  useEffect(() => {
    getFilterOptions(dispatch, i18n.language)
  }, [i18n.language, dispatch])
  return options
}

export default useFilterOptions