import React from 'react';
import "./HeroUniversity.scss"
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";

const HeroUniversity = ({ title, image }) => {
    return (
        <BackgroundImage backgroundPath={image}>
            <section className="university-hero">
                <h3 className="university-hero__title">
                    {title}
                </h3>
            </section>
        </BackgroundImage>
    )
}

export default HeroUniversity;
