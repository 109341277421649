import "./forForeigners.scss";
import { useTranslation } from "react-i18next";
import GenericButton from "../../genericComponents/genericButton/GenericButton";

const ForForeigners = ({ data }) => {
  const { t } = useTranslation()

  return (
    <section className="for-foreigners">
      {data.map((item, index) => (
        <div className={`for-foreigners__wrapper ${index % 2 !== 0 && 'for-foreigners__wrapper--invert'}`}>
          <img className="for-foreigners__image" src={item.image} alt="Imagen Turistas" />
          <div className={`for-foreigners__content ${index % 2 !== 0 && 'for-foreigners__content--invert'} `}>
            <h3 className="for-foreigners__subtitle">{t("scholarships:forForeigner")}</h3>
            <h2 className="for-foreigners__title">{item.name}</h2>
            <p className="for-foreigners__description" dangerouslySetInnerHTML={{ __html: item.content }} ></p>
            {/*<GenericButton text={t("common:seeMore")} />*/}
          </div>
        </div>
      ))}
    </section>
  )
}
export default ForForeigners