import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Scholarship from "../../components/educativeOffer/scholarship/Scholarship"
import Opportunities from "../../components/studyInColombia/opportunities/Opportunities"
import ForForeigners from "../../components/scholarships/forForeigners/ForForeigners"
import Hero from "../../components/scholarships/hero/Hero"
import Axios from 'axios'
import { ENDPOINTS } from '../../constants/endpoints'


const Scholarships = (props) => {
  const [image, setImage] = useState('')
  const [forForeigner, setForForeigner] = useState([])
  const { i18n } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = i18n.language === 'es' ? '' : '/en'
    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.SCHOLARSHIP_SECTION}`)
      .then(({ data }) => {
        setImage(data[0].principal_image)
      })
    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.SCHOLARSHIP_FOR_FOREIGNERS}`)
      .then(({ data }) => {
        console.log(data)
        setForForeigner(data.results)
      })

  }, [i18n.language])
  return (<>
    <Hero image={image} />
    <Scholarship availableScholarship={false} />
    <ForForeigners data={forForeigner} />
    <Opportunities />
  </>)
}
export default Scholarships