import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cities from '../../components/universityCity/cities/Cities'
import Hero from '../../components/universityCity/hero/Hero'
import Posts from '../../components/universityCity/posts/Posts'
import { ENDPOINTS } from '../../constants/endpoints'

const initialState = {
  title: '',
  content: '',
  principal_image: '',
  university_cities: []
}
const UniversityCity = (props) => {
  const [state, setState] = useState(initialState)
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'

    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.DISCOVER_COLOMBIA.CITIES}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])
  return (<>
    <Hero image={state.principal_image} title={state.title} />
    <Cities data={state.university_cities} content={state.content} />
    <Posts />
  </>)
}
export default UniversityCity