import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BackgroundBanner from '../../components/genericComponents/backgroundBanner/BackgroundBanner'
import Requirements from '../../components/higherEducationSystem/requirements/Requirements'
import ImageTextInterspersed from '../../components/genericComponents/imageTextInterspersed/ImageTextInterspersed'
import SectionHeader from '../../components/genericComponents/sectionHeader/SectionHeader'
import Slider from '../../components/higherEducationSystem/slider/Slider'
import { ENDPOINTS } from '../../constants/endpoints'
import Opportunities from '../../components/studyInColombia/opportunities/Opportunities'
import Rankings from '../../components/higherEducationSystem/rankings/Rankings'
import Hero from '../../components/higherEducationSystem/hero/Hero'
import Technical from '../../components/higherEducationSystem/technical/Technical'

const initialState = {
  principal_title: '',
  principal_subtitle: '',
  content: '',
  video: '',
  sliders: [],
  second_title: '',
  second_content: '',
  second_image: '',
  third_title: '',
  third_content: '',
  third_image: '',
  fourth_title: '',
  fourth_subtitle: '',
  fourth_content: '',
  fourth_image: '',
  fifth_title: '',
  fifth_subtitle: '',
  requirements: [],
  sixth_title: '',
  sixth_content: '',
  sixth_image: '',
  seventh_title: '',
  seventh_subtitle: '',
  seventh_content_part_one: '',
  seventh_image_part_one: '',
  seventh_content_part_two: '',
  seventh_image_part_two: '',
  eighth_title: '',
  eighth_subtitle: '',
  eighth_content: '',
  eighth_button_text: '',
  eighth_button_url: '',
  ninth_title: '',
  ninth_subtitle: '',
  ninth_content_part_one: '',
  ninth_image_part_one: '',
  ninth_content_part_two: '',
  ninth_image_part_two: '',
  ninth_button_text: '',
  ninth_button_url: ''
}
const HigherEducationSystem = (props) => {
  const [state, setState] = useState(initialState)
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'

    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.STUDY_IN_COLOMBIA.HIGHER}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])
  return (<>
    <Hero video={state.video} />
    <SectionHeader
      title={state.principal_subtitle}
      subtitle={state.principal_title}
      fullContent={state.content}
    />
    <Slider data={state.sliders} />
    <ImageTextInterspersed
      firstTitle={state.second_title}
      firstContent={state.second_content}
      firstImage={state.second_image}
      secondTitle={state.third_title}
      secondContent={state.third_content}
      secondImage={state.third_image}
    />
    <BackgroundBanner
      subtitle={state.fourth_title}
      title={state.fourth_subtitle}
      fullContent={state.fourth_content}
      path={state.fourth_image}
    />
    <Requirements
      subtitle={state.fifth_title}
      title={state.fifth_subtitle}
      data={state.requirements}
    />
    <Technical
      subtitle={state.sixth_title}
      content={state.sixth_content}
      image={state.sixth_image}
    />
    <ImageTextInterspersed
      title={state.seventh_subtitle}
      subtitle={state.seventh_title}
      firstContent={state.seventh_content_part_one}
      firstImage={state.seventh_image_part_one}
      secondContent={state.seventh_content_part_two}
      secondImage={state.seventh_image_part_two}
    />
    <Rankings
      title={state.eighth_subtitle}
      subtitle={state.eighth_title}
      fullContent={state.eighth_content}
      buttonText={state.eighth_button_text}
      url={state.eighth_button_url}
    />
    <ImageTextInterspersed
      title={state.ninth_subtitle}
      subtitle={state.ninth_title}
      firstContent={state.ninth_content_part_one}
      firstImage={state.ninth_image_part_one}
      secondContent={state.ninth_content_part_two}
      secondImage={state.ninth_image_part_two}
      buttonText={state.ninth_button_text}
      url={state.ninth_button_url}
    />
    <Opportunities />

  </>)
}
export default HigherEducationSystem