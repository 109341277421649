import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

import LoginReducer from './login/login.reducer'
import RolesReducer from "./roles/roles.reducer";
import HomeReducer from "./home/home.reducer";
import FiltersReducer from './filters/filters.reducer'
import OptionsReducer from './options/options.reducer'

const rootReducer = combineReducers({
    login: LoginReducer,
    home: HomeReducer,
    roles: RolesReducer,
    filters: FiltersReducer,
    options: OptionsReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));
