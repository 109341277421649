import React from 'react';
import ImageAndText from "../../genericComponents/imageAndText/ImageAndText";
import SectionHeader from '../../genericComponents/sectionHeader/SectionHeader';

const BeforeTravelling = ({ title, subtitle, firstImage, secondImage, secondText, firstText }) => {
    const list = [
        {
            img: firstImage,
            text: firstText
        },
        {
            img: secondImage,
            text: secondText
        }
    ]

    return (
        <div id="before-traveling">
            <SectionHeader title={subtitle} subtitle={title} />
            <ImageAndText list={list} />
        </div>
    );
};

export default BeforeTravelling;
