import React from 'react';
import {useTranslation} from "react-i18next";
import "./Articles.scss"
import Article from "./article/Article";

const Articles = ({articles}) => {
    const {t} = useTranslation();

    return (
        <div className={"articles"}>
            <div className={"articles__title"}>
                <span>{t("articles:title")}</span>
                <span>{t("articles:subtitle-1")}</span>
                <span>{t("articles:subtitle-2")}</span>
            </div>
            <div className={"articles__list"}>
                {articles.map((row, index) =>
                    <Article key={`articles-${index}`} data={row.post}/>
                )}
            </div>
        </div>
    );
}

export default Articles;