import React, {useEffect, useState} from 'react';
import "./FeatureArticles.scss"
import {useTranslation} from "react-i18next";
import axios from "axios";
import {ENDPOINTS} from "../../../constants/endpoints";
import Loader from "../../genericComponents/loader/Loader";
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader";
import SliderArticles from "../../genericComponents/sliderArticles/SliderArticles";

const initialState = {
    results: [],
    showLoading: true
}

const FeatureArticles = () => {
    const [state, setState] = useState(initialState)
    const {i18n, t} = useTranslation()

    useEffect(() => {
        async function fetchData() {
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.DISCOVER_COLOMBIA.POST}`)
            const newData = data.results.map( result => {
                return result.post
            })
            setState({
                results: newData
            })
        }

        fetchData().then(() => {})
    }, [i18n.language])

    return (
        <div>
            {
                state.showLoading ?
                    <div className="without-home">
                        <Loader/>
                    </div> :
                    <section className="study-work-options">
                        <SectionHeader
                            subtitle={t("discoverColombia:featureArticlesTitle")}
                            title={t("discoverColombia:featureArticlesSubtitle")}/>
                        <SliderArticles data={state.results}/>
                    </section>
            }
        </div>
    );
};

export default FeatureArticles;
