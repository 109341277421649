import React from 'react';
import "./HeroColombianCulture.scss"
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";

const HeroColombianCulture = ({ title, image }) => {
    return (
        <BackgroundImage backgroundPath={image}>
            <section className="university-hero">
                <h3 className="university-hero__title">
                    {title}
                </h3>
            </section>
        </BackgroundImage>
    );
};

export default HeroColombianCulture;
