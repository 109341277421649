import React from 'react';
import Slider from "react-slick";
import BoxInformationLocation from "../../../genericComponents/boxInformationLocation/BoxInformationLocation";

const SliderTravel = ({ data }) => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            {data.map((travel, index) => (
                <div key={`travel-${index}`} className="slide-container">
                    <BoxInformationLocation
                        img={travel.image}
                        text={travel.description}
                        title={travel.name}
                        location={`${travel.city.name}, ${travel.city.state.country.name}`}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default SliderTravel;
