import React from 'react';
import "./Remember.scss"

const Remember = ({second_section_content, second_section_subtitle, second_section_title, image}) => {
    return (
        <div className="remember-background">
            <div className="remember">
                <div className="remember__image">
                    <img src={image} alt="Imagen de Recuerda que hacer después de estudiar en Colombia"/>
                </div>
                <div className="remember__content">
                    <div className="remember__content--title">
                        <span>{second_section_title}</span>
                    </div>
                    <div className="remember__content--subtitle">
                        <span>{second_section_subtitle}</span>
                    </div>
                    <div className="remember__content--text">
                        <span dangerouslySetInnerHTML={{__html: second_section_content}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Remember;
