//LOGIN
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

//SING_UP
export const USER_SIGN_UP = 'USER_SIGN_UP';

//ROLES
export const CHANGE_ROLE = 'CHANGE_ROLE';

//HOME
export const HOME = 'HOME';
export const HIDDEN_HEADER = 'HIDDEN_HEADER';

//FILTERS
export const SET_PROGRAM_TYPE = 'CHANGE_PROGRAM_TYPE'
export const SET_STUDY_AREAS = 'CHANGE_STUDY_AREAS'
export const SET_MODALITIES = 'CHANGE_MODALITIES'
export const SET_INSTITUTIONS = 'CHANGE_INSTITUTIONS'
export const SET_CITIES = 'CHANGE_CITIES'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'

//FILTER OPTIONS 

export const ADD_FILTER_OPTIONS = 'SET OPTIONS'