import { useTranslation } from 'react-i18next'
import './city.scss'

const City = ({ data, big }) => {
  const { t } = useTranslation()
  const { name, educational_offer_count, image } = data
  return (<article className={`city ${big ? 'big' : 'little'}`}>
    <img className="city__image" src={image} alt="Imagen de ciudad" />
    <div className="city__container">
      <h4 className="city__name">{name}</h4>
      <p className="city__offer">{educational_offer_count} {t("educativeOffer:educativeOffers")}</p>
    </div>
  </article>)
}
export default City