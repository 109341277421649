import "./hero.scss"
import BackgroundImage from '../../genericComponents/backgroundImage/BackgroundImage'

const Hero = ({ image }) => {

  return (
    <BackgroundImage backgroundPath={image}>
      <div className="where-to-start__hero">
      </div>
    </BackgroundImage>
  )
}
export default Hero