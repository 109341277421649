import * as Types from '../types'

export const setStudyArea = (dispatch, id) => {
  dispatch({
    type: Types.SET_STUDY_AREAS,
    payload: id
  })
}
export const setModality = (dispatch, id) => {
  dispatch({
    type: Types.SET_MODALITIES,
    payload: id
  })
}
export const setProgramType = (dispatch, id) => {
  dispatch({
    type: Types.SET_PROGRAM_TYPE,
    payload: id
  })
}
export const setLocation = (dispatch, id) => {
  dispatch({
    type: Types.SET_CITIES,
    payload: id
  })
}
export const setInstitution = (dispatch, id) => {
  dispatch({
    type: Types.SET_INSTITUTIONS,
    payload: id
  })
}
export const clearAll = (dispatch) => {
  dispatch({
    type: Types.CLEAR_FILTERS,
  })
}