import React from 'react';
import "./PublicEmploymentService.scss"

const PublicEmploymentService = ({second_subtitle, subtitle, options, title}) => {
    return (
        <div className="public-employment-service">
            <div className="public-employment-service__title">
                <span>{title}</span>
            </div>
            <div className="public-employment-service__subtitle">
                <span>{subtitle}</span>
            </div>
            <div className="public-employment-service__second_subtitle">
                <span>{second_subtitle}</span>
            </div>
            <div className="public-employment-service__options">
                {options.map((option, index) => (
                    <div key={`options-employment-${index}`} className="options-employment">
                        <div className="options-employment__img">
                            <img src={option.image} alt="Imagen de opciones de empleo"/>
                        </div>
                        <div className="options-employment__title">
                            <span>{option.order}. {option.title}</span>
                        </div>
                        <div className="options-employment__text">
                            <span dangerouslySetInnerHTML={{ __html: option.content }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PublicEmploymentService;
