import React from 'react';
import "./HeroAfterStudy.scss"
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";
import {useTranslation} from "react-i18next";

const HeroAfterStudy = ({image}) => {
    const {t} = useTranslation();

    return (
        <BackgroundImage backgroundPath={image}>
            <section className="university-hero">
                <h3 className="university-hero__title">
                    {t("accessLink:afterStudy")}
                </h3>
            </section>
        </BackgroundImage>
    );
};

export default HeroAfterStudy;
