import { useState, useEffect } from 'react'
import "./options.scss"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import SliderArticles from "../../genericComponents/sliderArticles/SliderArticles"
import * as Types from "../../../store/types";
import axios from "axios";
import { ENDPOINTS } from "../../../constants/endpoints";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Loader from "../../genericComponents/loader/Loader";

const initialState = {
    results: [],
    showLoading: true
}
const Options = () => {
    const [stateOption, setStateOption] = useState(initialState)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({ type: Types.HIDDEN_HEADER, payload: true })
            setStateOption({ showLoading: true });
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let { data } = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.LIVE_IN_COLOMBIA.POST}`)
            const newData = data.results.map(result => {
                return result.post
            })
            setStateOption({
                results: newData
            })
        }

        fetchData().then(() => dispatch({ type: Types.HIDDEN_HEADER, payload: false }))
    }, [i18n.language])

    return (
        <>
            {
                stateOption.showLoading ?
                    <div className="without-home">
                        <Loader />
                    </div> :
                    <section className="study-work-options">
                        <SectionHeader
                            subtitle={t("studyAndWork:studyInColombia")}
                            title={t("studyAndWork:knowAll")} />
                        <SliderArticles data={stateOption.results} />
                    </section>
            }
        </>
    )
}
export default Options