import React from 'react';
import SliderYellowBox from '../../genericComponents/sliderYellowBox/SliderYellowBox';

const Reasons = ({reasons}) => {
    return(
        <div className={'reasons'}>
            {reasons.length > 0 && <SliderYellowBox data={reasons} keyName={'reasons-list'}/>}
        </div>
    );
}

export default Reasons;