import './technical.scss'

const Technical = ({ title, subtitle, content, image }) => {
  return (<section className="technical">
    <img className="technical__image" src={image} alt="Imagen Técnica" />
    <div className="technical__content">
      <h3 className="technical__subtitle">{subtitle}</h3>
      <h2 className="technical__title">{title}</h2>
      <div className="technical__description" dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  </section>)
}
export default Technical