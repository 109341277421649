import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import HeroStudyInColombia from "../../components/studyInColombia/hero/HeroStudyInColombia"
import Reasons from "../../components/studyInColombia/reasons/Reasons"
import {ENDPOINTS} from '../../constants/endpoints'
import Loader from "../../components/genericComponents/loader/Loader";
import * as Types from "../../store/types";
import {useDispatch} from "react-redux";

const initialState = {
    image: '',
    reasons: [],
    banner: '',
    principalText: '',
    second_image: '',
    showLoading: true
}
const StudyInColombia = () => {
    const [state, setState] = useState(initialState)
    const {i18n} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchData() {
            dispatch({type: Types.HIDDEN_HEADER, payload: true})
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.STUDY_IN_COLOMBIA.REASONS}`)
            data = data[0]
            setState({
                image: data.principal_image,
                reasons: data.reasons,
                secondImage: data.second_image,
                title: data.title,
                subtitle: data.subtitle,
                content: data.content
            })
        }

        fetchData().then( () => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
    }, [i18n.language])
    return (
        <>
            {
                state.showLoading ? <div className="without-home">
                        <Loader/>
                    </div> :
                    <>
                        <HeroStudyInColombia image={state.image}/>
                        <Reasons {...state} />
                    </>
            }
        </>
    );
}

export default StudyInColombia