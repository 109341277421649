import { useTranslation } from 'react-i18next'
import SectionHeader from '../../genericComponents/sectionHeader/SectionHeader'
import Mosaic from '../mosaic/Mosaic'
import './cities.scss'

const Cities = ({ cities }) => {
  const { t } = useTranslation()
  return (<div>
    <SectionHeader subtitle={t("educativeOffer:citiesSubtitle")} title={t("educativeOffer:citiesTitle")} />
    {
      cities.length > 0 &&
      <Mosaic cities={cities} />
    }
  </div>)
}
export default Cities