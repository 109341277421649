import { createPortal } from 'react-dom'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import "./modal.scss"

const Modal = ({ children, close, bottom, full }) => {
  const { t } = useTranslation()
  const container = useRef(null)
  const clickOnGray = (e) => {
    if (e.target === container.current) {
      close()
    }
  }

  const ModalContainer = () => {
    return (
      <div className="modal-container" onClick={clickOnGray} ref={container}>
        <div className={`modal-container__content ${full && 'modal-container__content--full'}`} >
          <button className="modal-container__close" onClick={close}>
            <i className="ri-close-line" />
          </button>
          {children}
          {bottom}
        </div>
      </div>
    )
  }
  return (
    <>
      {createPortal(<ModalContainer />, document.getElementById('modal'))}
    </>
  )
}
export default Modal