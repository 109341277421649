import React from 'react';
import "./Loader.scss"
import LoaderGif from "../../../assets/gif/loader.gif"

const Loader = () => {
    return (
        <div className="loader">
            <img src={LoaderGif} alt="Cargando"/>
        </div>
    );
};

export default Loader;
