import React from 'react';
import {useTranslation} from "react-i18next";
import "./AboutUs.scss"
import GenericButton from "../../genericComponents/genericButton/GenericButton";
import {useHistory} from "react-router-dom";
import * as Routes from "../../../router/routes";

const AboutUs = ({text}) => {
    const {t} = useTranslation();

    const history = useHistory();
    const goTo = route => history.push(route);

    return(
        <div className={"about-us col-sm-12 col-md-8"}>
            <div dangerouslySetInnerHTML={{__html: text}} />
            <div className={"about-us__button"}>
                <GenericButton text={t("aboutUs:wantStart")} onClick={() => goTo(Routes.LIVE_IN_COLOMBIA)}/>
            </div>
        </div>
    );
}

export default AboutUs;