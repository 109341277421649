import BoxWithShadow from '../boxWithShadow/BoxWithShadow';
import './timelineEvent.scss';

const TimelineEvent = ({ invert, title, content, image }) => {
    if (invert) {
        return (
            <>
                <div className="timeline-event timeline-event--invert">
                    <div className="timeline-event__circle timeline-event__circle--invert">
                        <div className="timeline-event__point" />
                    </div>
                    <h3 className="timeline-event__title">{title}</h3>
                    <div className="timeline-event__content" dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                <BoxWithShadow invert={true} src={image} />
            </>
        );
    } else {
        return (
            <>
                <BoxWithShadow src={image} />
                <div className="timeline-event">
                    <div className="timeline-event__circle ">
                        <div className="timeline-event__point" />
                    </div>
                    <h3 className="timeline-event__title">{title}</h3>
                    <div className="timeline-event__content" dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
            </>
        );
    }
};
export default TimelineEvent;
