import { useTranslation } from "react-i18next";
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader";
import Timeline from "../../genericComponents/timeline/Timeline";
import "./knowMore.scss";

const KnowMore = ({ data }) => {
  const { t } = useTranslation()

  return (
    <section className="know-more">
      <SectionHeader subtitle={t("learnSpanish:knowMore")} title={t("learnSpanish:teachingNumbers")} />
      <Timeline data={data} />
    </section>)
}
export default KnowMore