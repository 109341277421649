import React from 'react';
import { useTranslation } from "react-i18next";
import "./showVideo.scss"

const ShowVideo = ({ title, subtitle, onClick, home }) => {
    const { t } = useTranslation();

    return (
        <div className={"showVideo"}>
            {!home &&
                <div className="showVideo__icon-container">
                    <button className="btn-play" onClick={onClick}>
                        <i className="ri-play-fill"></i>
                    </button>
                </div>
            }
            <h1>{title}</h1>
            <span>{subtitle}</span>
            {home &&
                <div className="showVideo__icon-container">
                    <button className="btn-play" onClick={onClick}>
                        <i className="ri-play-fill"></i>
                    </button>
                    <p className="showVideo__icon-text">
                        {t("common:watchVideo")}
                    </p>
                </div>

            }
        </div>
    );
};

export default ShowVideo;
