import React from 'react';
import Slider from "react-slick";
import './Sponsors.scss';

const Sponsors = ({partners}) => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
        ]
    }

    const gotTo = (url) => {
        window.open(url, '_blank')
    }

    return (
        <div className="sponsors">
            <Slider {...settings}>
                {partners.map((partner, index) =>
                    <div key={`sponsor-${index}`} style={{ width: 100 }}>
                        <img src={partner.logo} alt="Logo de socio" onClick={() => gotTo(partner.link)}/>
                    </div>
                )}
            </Slider>
        </div>
    );
}

export default Sponsors;