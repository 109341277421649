import React from 'react';
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";
import "./HeroLiveInColombia.scss"

const HeroLiveInColombia = ({title, image}) => {
    return (
        <BackgroundImage backgroundPath={image}>
            <section className="live-in-colombia-hero">
                <h3 className="live-in-colombia-hero__title">
                    {title}
                </h3>
            </section>
        </BackgroundImage>
    )
};

export default HeroLiveInColombia;
