import React, {useState} from 'react';
import "./Tips.scss"
import SliderYellowBox from "../../genericComponents/sliderYellowBox/SliderYellowBox";
import {useTranslation} from "react-i18next";
import Modal from "../../genericComponents/modal/Modal";

const Tips = ({tips}) => {
    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState('');
    const {t} = useTranslation();
    const newTips = tips.map(tip => {
         return {
             ...tip,
             content: tip.description,
             title: `${tip.id}. ${tip.title}`,
             textButton: t("educativeOffer:wantToRead"),
             onClick: () => showModalContent(tip.full_content)
         };
    })

    const showModalContent = (text) => {
        setContent(text);
        setShowModal(true);
    }

    const closeModal = () => {
        setContent('');
        setShowModal(false)
    }

    return (
        <div className="tips">
            <SliderYellowBox data={newTips} keyName={'tips-list'}/>
            {showModal &&
            <div className="testimonial__video">
                <Modal close={closeModal}>
                    <div className="tip_full_content" dangerouslySetInnerHTML={{__html: content}}/>
                </Modal>
            </div>}
        </div>

    );
};

export default Tips;
