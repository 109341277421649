import axios from 'axios';
import * as Types from '../types'
import {ENDPOINTS} from '../../constants/endpoints'

export const getDataHome = (language) => async (dispatch, getState) => {
    try {
        const response = await axios.get(`${ENDPOINTS.BASE}${language}${ENDPOINTS.HOME.MAIN}`)
        dispatch({
            type: Types.HOME,
            payload: response.data
        })

    } catch (error) {
        console.error(error);
    }
}