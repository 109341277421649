import React from 'react';
import "./RegionList.scss"

const RegionList = ({regions}) => {
    return (
        <>
            {
                regions.map(region =>
                    <div className="region-list">
                        <div className="region-list__image">
                            <img src={region.image} alt="Imagen de Región"/>
                        </div>
                        <div className="region-list__text">
                            <div className="region-list__text--content">
                                <div className="regions-title">
                                    <span>{region.name}</span>
                                </div>
                                <div className="regions-text">
                                <span dangerouslySetInnerHTML={{__html: region.description}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default RegionList;
