import './steps.scss'
import CardWithModal from '../../genericComponents/cardWithModal/Card'
import GenericButton from '../../genericComponents/genericButton/GenericButton'
import { useTranslation } from 'react-i18next'

const Steps = ({ steps, content }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="steps__container">
        <div className="steps__title" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="steps__list">
          {steps.sort((a, b) => a.order - b.order).map(({ content, ...step }) => <CardWithModal key={step.id} {...step} fullContent={content} />)}
        </div>
        <h3 className="steps__subtitle">{t("whereToStart:weHave")}</h3>
        <GenericButton text={t("whereToStart:download")} />
      </div>
    </>)
}
export default Steps