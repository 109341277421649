import "./filters.scss"
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"
import Select from "./select/Select"
import { setLocation, setInstitution, setModality, setProgramType, setStudyArea, clearAll } from '../../../store/filters/filters.actions'
import useFilterOptions from "../../../hooks/useFilterOptions"

const Filters = ({ showMap, changeShowMap }) => {
  const filters = useSelector(store => store.filters)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { programs, studyAreas, modalities, institutions, locations } = useFilterOptions()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeProgram = (id) => {
    setProgramType(dispatch, id)
  }
  const changeStudyArea = (id) => {
    setStudyArea(dispatch, id)
  }
  const changeModality = (id) => {
    setModality(dispatch, id)
  }
  const changeInstitution = (id) => {
    setInstitution(dispatch, id)
  }
  const changeLocation = (id) => {
    setLocation(dispatch, id)
  }
  const clearAllFilters = () => {
    clearAll(dispatch)
  }

  return (<section className="filters-container">
    <Select current={filters.program_type} placeholder={t("headerHome:typeOfProgram")} onChange={changeProgram} options={programs} icon={<i className="ri-book-2-line" />} />
    <Select current={filters.study_area} placeholder={t("headerHome:studyArea")} onChange={changeStudyArea} options={studyAreas} icon={<i className="ri-bookmark-3-line" />} />
    <Select current={filters.modality} placeholder={t("headerHome:modality")} onChange={changeModality} options={modalities} icon={<i className="ri-computer-line" />} />
    <Select current={filters.institution} placeholder={t("headerHome:institution")} onChange={changeInstitution} options={institutions} icon={<i className="ri-building-4-line" />} />
    <Select current={filters.city} placeholder={t("headerHome:location")} onChange={changeLocation} options={locations} icon={<i className="ri-book-2-line" />} />
    <button className="filters__clear" onClick={clearAllFilters}><i className="ri-close-fill" /></button>
    <label className={`filters__checkbox ${showMap && 'filters__checkbox--checked'}`} htmlFor="map">Mapa</label>
    <input className="filters__checkbox-input" hidden id="map" type="checkbox" defaultChecked={showMap} onChange={changeShowMap} />
  </section>)
}
export default Filters