
import GenericButton from "../../genericComponents/genericButton/GenericButton"
import "./opportunity.scss"

const Opportunity = ({ title, subtitle, firstTitle, firstSubtitle, firstContent, firstImage, secondTitle, secondContent, secondImage, buttonText, url, gray }) => {
  return (<section className={`image-text-interspersed ${gray && 'image-text-interspersed--gray'}`}>
    <div className="image-text-interspersed__wrapper">
      <div className="image-text-interspersed__content">
        <img className="image-text-interspersed__image" src={firstImage} alt="Imagen" />
        <div className="image-text-interspersed__list">
          {firstTitle &&
            <>
              <h2 className="image-text-interspersed__subtitle" >{firstTitle}</h2>
              <h2 className="image-text-interspersed__title" >{firstSubtitle}</h2>
            </>
          }
          <div dangerouslySetInnerHTML={{ __html: firstContent }}></div>
        </div>
      </div>
      <div className="image-text-interspersed__content">
        <div className="image-text-interspersed__list image-text-interspersed__list--last" >
          {secondTitle &&
            <h2 className="image-text-interspersed__title">{firstTitle}</h2>
          }
          <div dangerouslySetInnerHTML={{ __html: secondContent }}>
          </div>
          {buttonText &&
            <a href={url} target="_blank" rel="noreferrer" className="image-text-interspersed__btn">
              <GenericButton text={buttonText} />
            </a>
          }
        </div>
        <img className="image-text-interspersed__image" src={secondImage} alt="Imagen" />
      </div>
    </div>
  </section>)
}
export default Opportunity