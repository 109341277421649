import './cities.scss'
import City from './City/City'
import Map from './Map/Map'
import { useState } from 'react'

const Cities = ({ data, content }) => {
  const [center, setCenter] = useState([4.0744423, -73.5851376])
  const [zoom, setZoom] = useState(6)

  const changeCenter = (coords) => {
    setCenter(coords)
    setZoom(12)
  }
  return (
    <section className="cities-u">
      <div className="cities-u__content" dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="cities-u__grid">
        <div className="cities-u__list">
          {data.map((city) => <City {...city} changeCenter={changeCenter} />)}
        </div>
        <div className="cities-u__map">
          <Map
            zoom={zoom}
            center={center}
            markers={data}
            changeCenter={changeCenter}
          />
        </div>
      </div>
    </section>)
}
export default Cities