import "./BackgroundImage.scss"

const BackgroundImage = ({ backgroundPath, children, overlay }) => {
    return (
        <div className={"img-background"}>
            <img src={backgroundPath} alt="Imagen de fondo" />
            {overlay &&
                <div className="img-background__overlay"></div>
            }
            {children}
        </div>
    );
};

export default BackgroundImage;
