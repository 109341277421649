export const AccessLinkOptions = {
    discoverColombia: {
        status: false,
        name: 'footer:discoverColombia',
        base: '/discover-colombia',
        options: [
            { name: 'accessLink:history', url: '/discover-colombia/history' },
            { name: 'accessLink:knowledge', url: '/discover-colombia/ancestral-knowledge' },
            { name: 'accessLink:culture', url: '/discover-colombia/colombian-culture' },
            { name: 'accessLink:cities', url: '/discover-colombia/cities' },
        ]
    },
    studyInColombia: {
        status: false,
        name: 'footer:studyInColombia',
        base: '/study-in-colombia',
        options: [
            { name: 'accessLink:reasons', url: '/study-in-colombia/reasons' },
            { name: 'accessLink:learnSpanish', url: '/study-in-colombia/learn' },
            { name: 'accessLink:start', url: '/study-in-colombia/where-to-start' },
            { name: 'accessLink:system', url: '/study-in-colombia/higher-education-system' },
            { name: 'accessLink:practice', url: '/study-in-colombia/internship-and-volunteering' }
        ]
    },
    educativeOffer: {
        status: false,
        name: 'footer:educativeOffer',
        base: '/educative-offer',
        options: [
            { name: 'accessLink:programs', url: '/educative-offer/programs' },
            { name: 'accessLink:scholarships', url: '/educative-offer/scholarships' }
        ]
    },
    liveInColombia: {
        status: false,
        name: 'footer:liveInColombia',
        base: '/live-in-colombia',
        options: [
            { name: 'accessLink:cost', url: '/live-in-colombia/cost' },
            { name: 'accessLink:studyAndWork', url: '/live-in-colombia/study-and-work' },
            { name: 'accessLink:afterStudy', url: '/live-in-colombia/after-study' }
        ]
    },
    publications: {
        status: false,
        name: 'footer:resources',
        base: '/publications',
        options: [
            { name: 'accessLink:lastNews', url: '/publications' },
            { name: 'accessLink:inWorld', url: '/publications' },
            { name: 'accessLink:stats', url: '/publications' }
        ]
    },
    contact: {
        status: false,
        name: 'footer:contact',
        base: '/contact',
        url: '/contact',
        options: []
    }
}






















