import {CHANGE_ROLE} from "../types";

const initialState = {
    role: 'STUDENTS'
}

const RolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ROLE :
            return {
                ...state,
                role: action.payload
            }
        default :
            return {
                ...state
            }
    }
}

export default RolesReducer;