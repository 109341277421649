import { useState, useEffect, useRef } from 'react'
import "./select.scss";

const Select = ({ options, current, onChange, placeholder, icon }) => {
  const [open, setOpen] = useState(false)
  const select = useRef(null)

  const [filteredOptions, setFilteredOptions] = useState([])

  const filterOptions = (event) => {
    setFilteredOptions(
        options.filter(
            option => option.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
    );
  };

  const toggleSelect = () => {
    setOpen(!open)
  }
  useEffect(() => {
    function onClick(e) {
      if (e.target !== select.current && open) {
        setOpen(false)
      }
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [open])
  const name = current && options.length > 0 ? options.filter(opt => opt.id === current)[0].name : placeholder
  return (
    <div ref={select} className={`select ${open ? 'select--active' : ''}`} onClick={toggleSelect}>
      <span>{icon}</span><input onChange={filterOptions} className={`select__current ${current ? 'select__current--selected' : ''}`} type="text" placeholder={name}/> {open ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}
      {open &&
        <ul className="select__options">
          <li className={`select__option `} onClick={() => { onChange(null) }}>---</li>
          {filteredOptions.length > 0? filteredOptions.map(opt =>
            <li
              key={opt.id}
              className={`select__option ${opt.id === current ? 'select__option--active' : ''}`}
              onClick={() => { onChange(opt.id) }}
            >{opt.name}</li>):
            options?.map(opt =>
              <li
                  key={opt.id}
                  className={`select__option ${opt.id === current ? 'select__option--active' : ''}`}
                  onClick={() => { onChange(opt.id) }}
              >{opt.name}</li>
            )
          }
        </ul>
      }
    </div>
  )
}
export default Select
