import { useTranslation } from 'react-i18next'
import CommunicationCard from '../../genericComponents/communicationCard/CommunicationCard'
import SectionHeader from '../../genericComponents/sectionHeader/SectionHeader'
import './volunteering.scss'

const Volunteering = ({ data }) => {
  const { t } = useTranslation()
  return (<section className="volunteering">
    <SectionHeader subtitle={t("internship:volunteering")} title={t("internship:whereTo")} />
    <div className="volunteering__places">
      {data.map((item) => <CommunicationCard
        key={item.id}
        {...item}
        title={item.name}
        src={item.image}
        place={`${item.city.name}, ${item.city.state.country.name}`}
      />)}
    </div>
    <SectionHeader subtitle={t("internship:internships")} content={t("internship:toDo")} />
  </section>)
}
export default Volunteering