import { useState } from 'react'
import { createPortal } from 'react-dom'
import Modal from "../modal/Modal";
import "./card.scss";

const CardWithModal = ({ title, summary, order, fullContent, content, image, modal }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  return (
    <>
      <div className="card-modal" onClick={openModal}>
        <img className="card-modal__image" src={image} alt="Imagen de modal" />
        <h3 className="card-modal__title">{order && `${order}.`} {title}</h3>
        <p className="card-modal__content">{summary}</p>
        {fullContent &&
          <p className="card-modal__content" dangerouslySetInnerHTML={{ __html: fullContent }}></p>}
      </div >
      {
        open && modal && (
          createPortal(<Modal close={closeModal} full>
            <div className="card-modal__detail">
              <h3 className="card-modal__title">{order && `${order}.`} {title}</h3>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </Modal>, document.getElementById("modal"))
        )
      }
    </>
  )
}
export default CardWithModal