import "./underConstruction.scss"
import Image from "../../assets/images/under-construction.svg"
import { useEffect } from 'react'
import { useTranslation } from "react-i18next"

const UnderConstruction = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (<>
    <div className="header-background-space" />
    <section className="under-construction">
      <div>
        <h1 className="under-construction__title">{t("construction:oops")}</h1>
        <h2 className="under-construction__subtitle">{t("construction:underConstruction")}</h2>
      </div>
      <img alt="Bajo construcción" className="under-construction__image" src={Image} alt="<a href='https://www.freepik.es/vectors/negocios'>Vector de Negocios creado por pikisuperstar - www.freepik.es</a>" srcset="" />
    </section>
  </>)
}
export default UnderConstruction