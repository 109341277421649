import "./communicationCard.scss"
import { useHistory } from "react-router-dom";
import * as Routes from "../../../router/routes";

const CommunicationCard = ({ src, title, description, place, component, date, whatsApp, link, url, fit, id }) => {
    const history = useHistory();
    const goTo = () => history.push(link);

    const goToUniversityDetail = () => {
        history.push(`${Routes.UNIVERSITY_DETAIL}/${id}`)
    }

    return (
        <a className="communication-card-link" href={url} target="_blank" rel="noreferrer">
            <div className="communication-card" onClick={() => goTo()}>
                <img className={`communication-card__image ${fit === 'contain' && 'communication-card__image--contain'}`} src={src} alt="Imagen de comunicación" />
                <p className="communication-card__place-date">
                    <span><i className="ri-map-pin-fill" />{place}</span>
                    {
                        date &&
                        <span>{date}</span>
                    }
                </p>
                <h3 className="communication-card__title" onClick={() => goToUniversityDetail()}>{title}</h3>
                <p className="communication-card__description">{description.slice(0, 300)}</p>
                <div className="communication-card__actions">
                    {component}
                    {whatsApp && <i className="ri-whatsapp-fill" />}
                </div>
            </div>
        </a>
    )
}
export default CommunicationCard
