import {USER_LOGIN} from "../types";

const initialState = {
    token: ''
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                token: action.payload
            }
        default:
            return {...state}
    }
}

export default LoginReducer;