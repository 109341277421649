import Axios from 'axios'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import EmploymentService from "../../components/studyAndWork/employmentService/EmploymentService"
import Hero from "../../components/studyAndWork/hero/Hero"
import Options from "../../components/studyAndWork/options/Options"
import TaxResidentCertificate from "../../components/studyAndWork/taxResidentCertificate/TaxResidentCertificate"
import WantToWork from "../../components/studyAndWork/wantToWork/WantToWork"
import { ENDPOINTS } from '../../constants/endpoints'
import ImageTextInterspersed from '../../components/genericComponents/imageTextInterspersed/ImageTextInterspersed'

const initialState = {
  id: 1,
  principal_image: null,
  principal_title: "",
  title: "",
  subtitle: "",
  employment_service_options: [],
  content: "",
  hire_foreigner_section_title: "",
  hire_foreigner_section_subtitle: "",
  first_content_hire_foreigner_section: "",
  first_image_hire_foreigner_section: null,
  second_content_hire_foreigner_section: "",
  second_image_hire_foreigner_section: null,
  study_and_work_title: "",
  study_and_work_subtitle: "",
  study_and_work_content: "",
  study_and_work_full_content: "",
  certificate_content: "",
  certificate_image: null,
  certificate_full_content: "",
  tax_obligations_for_foreigners_full_content: ""
}
const StudyAndWork = (props) => {
  const [state, setState] = useState(initialState)
  const {t} = useTranslation()
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'
    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.LIVE_IN_COLOMBIA.STUDY_AND_WORK}`)
      .then(({ data }) => {
        console.log(data)
        setState(data[0])
      })
  }, [i18n.language])
  return (<>
    <Hero
      image={state.principal_image}
      title={state.principal_title}
    />
    <EmploymentService
      title={state.title}
      subtitle={state.subtitle}
      content={state.content}
    />
    <ImageTextInterspersed
      title={state.hire_foreigner_section_title}
      subtitle={state.hire_foreigner_section_subtitle}
      firstContent={state.first_content_hire_foreigner_section}
      firstImage={state.first_image_hire_foreigner_section}
      secondContent={state.second_content_hire_foreigner_section}
      secondImage={state.second_image_hire_foreigner_section}
      contentModal={state.tax_obligations_for_foreigners_full_content}
      buttonText={t("common:seeMore")}
      gray
    />
    <WantToWork
      title={state.study_and_work_title}
      subtitle={state.study_and_work_subtitle}
      content={state.study_and_work_content}
      contentModal={state.study_and_work_full_content}
    />
    <TaxResidentCertificate
        contentModal={state.certificate_full_content}
        content={state.certificate_content}
        image={state.certificate_image} />
    <Options data={state.employment_service_options} />
  </>)
}
export default StudyAndWork