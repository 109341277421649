import { useEffect, useState } from 'react'
import Axios from "axios"
import { ENDPOINTS } from "../../constants/endpoints"
import { useTranslation } from 'react-i18next'
import SliderYellowBox from '../../components/genericComponents/sliderYellowBox/SliderYellowBox'
import Opportunities from '../../components/studyInColombia/opportunities/Opportunities'
import Hero from '../../components/whereToStart/hero/Hero'
import Steps from '../../components/whereToStart/steps/Steps'
import GenericButton from '../../components/genericComponents/genericButton/GenericButton'
import Modal from '../../components/genericComponents/modal/Modal'

const initialState = {
  principal_image: '',
  principal_text: '',
  faq_content: '',
  steps: [],
  documents: []
}
const WhereToStart = (props) => {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState(initialState)
  const [showModal, setShowModal] = useState(false)

  const close = () => {
    setShowModal(false)
  }
  const open = () => {
    setShowModal(true)
  }

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'

    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.STUDY_IN_COLOMBIA.START}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])

  const modifiedData = state.documents.map(({ title, ...document }) => ({ ...document, subtitle: title }))
  return (<>
    <Hero image={state.principal_image} />
    <Steps steps={state.steps} content={state.principal_text} />
    {state.documents.length > 0 &&
      <SliderYellowBox data={modifiedData} />
    }
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <GenericButton text={t("whereToStart:more")} onClick={open} />
    </div>
    {showModal &&
      <Modal full close={close}>
        <div className="modal-faq" dangerouslySetInnerHTML={{ __html: state.faq_content }}></div>
      </Modal>}
    <Opportunities />
  </>)
}
export default WhereToStart