import CommunicationCard from "../../../genericComponents/communicationCard/CommunicationCard"
import Slider from 'react-slick'

import "./scholarshipSlider.scss"

const ScholarshipSlider = ({ data }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {
        data.map((scholarship, index) => (
          <div key={`ScholarshipSlider-${index}`} className="scholarship-slide">
            <CommunicationCard
              {...scholarship}
              place={`${scholarship.city.name},${scholarship.city.state.country.name}`}
              src={scholarship.image}
              title={scholarship.name}
            />
          </div>
        ))
      }
    </Slider>)
}
export default ScholarshipSlider