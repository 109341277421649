import axios from 'axios';
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from '../../../constants/endpoints';
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader";
import SliderArticles from "../../genericComponents/sliderArticles/SliderArticles";
import "./opportunities.scss";

const Opportunities = (props) => {
  const { t, i18n } = useTranslation()
  const [articles, setArticles] = useState([])

  useEffect(() => {
    async function fetchData() {
      const LANGUAGE = i18n.language === 'en' ? '/en' : ''
      let { data } = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.STUDY_IN_COLOMBIA.POSTS}`)
      setArticles(data.map(post => ({ ...post.post, order: post.order })))
    }
    fetchData()
  }, [i18n.language])

  return (
    <section className="opportunities">
      <SectionHeader subtitle={t("opportunities:studyInColombia")} title={t("opportunities:findOutOpportunities")} />
      <SliderArticles data={articles} />
    </section>)
}
export default Opportunities