import React from 'react';
import "./Testimonials.scss"
import {useTranslation} from "react-i18next";
import Testimonial from "./testimonial/Testimonial";

const Testimonials = ({testimonies}) => {
    const {t} = useTranslation();

    return (
        <>
            {testimonies.length > 0 &&
                <div className="testimonials">
                    <div className="testimonials__background"/>
                    <div className="testimonials__title">
                        <span>{t("testimonials:title")}</span>
                        <span>{t("testimonials:subtitle-1")}</span>
                        <span>{t("testimonials:subtitle-2")}</span>
                    </div>
                    <Testimonial data={testimonies}/>
                </div>
            }
        </>
    );
}

export default Testimonials;
