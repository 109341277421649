import React from 'react';
import "./ProgramInformation.scss"
import Default from "../../assets/images/default.jpg"

const ProgramInformation = ({name, description, image, whatsapp_url, cost, start_date, city, program_type, study_area, modality, institution, registration_url, latitude, longitude}) => {

    const goTo = () => window.open(whatsapp_url, '_blank', 'noopener,noreferrer');

    return (
        <div className="program-information">
            <div className="program-information__logo">
                <img src={image? image:Default} alt="Logo de Programa" width={200}/>
            </div>
            <br/>
            <div className="program-information__name">{name}</div>
            <div className="program-information__institution">{institution.name}</div>
            <div className="program-information__address">{city?.name}</div>
            <div className="program-information__whatsapp">
                <span onClick={() => goTo()}>
                    {whatsapp_url}
                </span>
            </div>
            <div className="program-information__description">{description}</div>
        </div>
    );
};

export default ProgramInformation;
