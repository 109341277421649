import React, { useState, useEffect } from 'react';
import HeroLiveInColombia from "../../components/liveInColombia/hero/HeroLiveInColombia";
import Cost from "../../components/liveInColombia/costs/Costs";
import BeforeTravelling from "../../components/liveInColombia/beforeTravelling/BeforeTravelling";
import HostingService from "../../components/liveInColombia/hostingServices/HostingService";
import Tips from "../../components/liveInColombia/tips/Tips";
import Travel from "../../components/liveInColombia/travel/Travel";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "../../constants/endpoints";
import Loader from "../../components/genericComponents/loader/Loader";
import * as Types from "../../store/types";
import axios from "axios";
import { useDispatch } from "react-redux";

const initialState = {
    advice_subtitle: "",
    advice_title: "",
    content: "",
    cost_video: "",
    costs: "",
    first_image_advice: "",
    first_content_advice: "",
    hosting_service_subtitle: "",
    hosting_service_title: "",
    id: "",
    principal_image: "",
    principal_title: "",
    second_content_advice: "",
    second_image_advice: "",
    services: [],
    subtitle: "",
    tips: [],
    title: "",
    travel_options: [],
    travel_section_description: "",
    travel_section_subtitle: "",
    travel_section_title: "",
    showLoading: true
}

const LiveInColombia = () => {
    const [state, setState] = useState(initialState);
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({ type: Types.HIDDEN_HEADER, payload: true })
            setState({ showLoading: true });
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let { data } = await axios.get(`${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.LIVE_IN_COLOMBIA.LIFE_IN_COLOMBIA}`)
            setState(data[0])
        }

        fetchData().then(() => dispatch({ type: Types.HIDDEN_HEADER, payload: false }))
    }, [i18n.language])

    return (
        <>
            {
                state.showLoading ? <div className="without-home">
                    <Loader />
                </div> :
                    <>
                        <HeroLiveInColombia title={state.principal_title} image={state.principal_image} />
                        <Cost
                            video={state.cost_video}
                            title={state.title}
                            subtitle={state.subtitle}
                            content={state.content}
                            costs={state.costs} />
                        <BeforeTravelling
                            subtitle={state.advice_subtitle}
                            title={state.advice_title}
                            firstImage={state.first_image_advice}
                            secondImage={state.second_image_advice}
                            secondText={state.second_content_advice}
                            firstText={state.first_content_advice}
                        />
                        <HostingService
                            services={state.services}
                            subtitle={state.hosting_service_subtitle}
                            title={state.hosting_service_title} />
                        <Tips tips={state.tips} />
                        <Travel
                            title={state.travel_section_title}
                            subtitle={state.travel_section_subtitle}
                            description={state.travel_section_description}
                            options={state.travel_options}
                        />
                    </>
            }
        </>
    );
};

export default LiveInColombia;
