import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import GenericButton from "../../genericComponents/genericButton/GenericButton"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import ScholarshipSlider from "./scholarshipSlider/ScholarshipSlider"
import "./scholarship.scss"
import Axios from 'axios'
import { ENDPOINTS } from '../../../constants/endpoints'
import Loader from "../../genericComponents/loader/Loader";
import { useHistory } from 'react-router-dom'

const initialState = {
    title: '',
    subtitle: '',
    content: '',
    image: '',
    data: [],
    showLoading: true
}
const Scholarship = ({ availableScholarship = true }) => {
    const [state, setState] = useState(initialState)
    const { t, i18n } = useTranslation()
    const history = useHistory()

    useEffect(() => {
        const lang = i18n.language === 'es' ? '' : '/en'

        async function fetchData() {
            setState({ showLoading: true });
            const { data: scholarship } = await Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.SCHOLARSHIP_SECTION}`)
            const { data: special } = await Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.SPECIAL_CORPORATION_PROGRAMS}`)
            setState({
                title: scholarship[0].title,
                subtitle: scholarship[0].subtitle,
                content: scholarship[0].content,
                image: scholarship[0].principal_image,
                data: special.results,
                showLoading: false
            })
        }

        fetchData();
    }, [i18n.language])

    const goToScholarships = () => {
        history.push('/educative-offer/scholarships')
    }

    return (
        <>
            {
                state.showLoading ?
                    <div>
                        <Loader />
                    </div> :
                    <section className="scholarship col-xs-12 col-md-10">
                        <SectionHeader
                            subtitle={state.title}
                            title={state.subtitle}
                            content={state.content}
                            full
                        />
                        <div className="scholarship__container">
                            <ScholarshipSlider data={state.data} />
                        </div>
                        {
                            availableScholarship && (
                                <div className="scholarship__action">
                                    <p className="scholarship__action-title">{t("educativeOffer:availableScholarship")}</p>
                                    <GenericButton onClick={goToScholarships} text={t("educativeOffer:clickHere")} />
                                </div>
                            )
                        }
                    </section>
            }
        </>
    )
}

export default Scholarship