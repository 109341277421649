import React from 'react';
import "./Characteristics.scss"
import {useTranslation} from "react-i18next";

const Characteristics = ({characteristics}) => {
    const {t} = useTranslation()

    return (
        <div>
            <div className="culture-characteristics">
                <div className="characteristics__title">
                    <span>{t("discoverColombia:characteristicsTitle")}</span>
                </div>
                <div className="characteristics__subtitle">
                    <span>{t("discoverColombia:characteristicsSubtitle-1")}</span>
                    <span>{t("discoverColombia:characteristicsSubtitle-2")}</span>
                </div>
                <div className="characteristics__second-subtitle">
                    <span>{t("discoverColombia:characteristicsSecondSubtitle-1")}</span>
                    <span>{t("discoverColombia:characteristicsSecondSubtitle-2")}</span>
                </div>
            </div>
            <div className="culture-characteristics__options">
                {characteristics.map((option, index) => (
                    <div key={`culture-characteristics-${index}`} className="culture-characteristics__list">
                        <div className="culture-characteristics__list--img">
                            <img src={option.image} alt="Imagen de cultura"/>
                        </div>
                        <div className="culture-characteristics__list--title">
                            <span>{option.order}. {option.name}</span>
                        </div>
                        <div className="culture-characteristics__list--text">
                            <span>{option.description}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Characteristics;
