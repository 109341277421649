import { useEffect, useState } from 'react'
import Axios from "axios"
import { ENDPOINTS } from "../../constants/endpoints"
import { useTranslation } from 'react-i18next'
import Hero from '../../components/internship/hero/Hero'
import Opportunities from '../../components/studyInColombia/opportunities/Opportunities'
import Opportunity from '../../components/internship/opportunity/Opportunity'
import Volunteering from '../../components/internship/volunteering/Volunteering'

const initialState = {
  title: '',
  subtitle: '',
  content: '',
  principal_image: '',
  first_image: '',
  first_content: '',
  second_image: '',
  second_content: '',
  volunteer_places: []
}
const Internship = (props) => {
  const { i18n } = useTranslation()
  const [state, setState] = useState(initialState)
  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'

    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.STUDY_IN_COLOMBIA.INTERNSHIP}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])
  return (<>
    <Hero image={state.principal_image} />
    <Opportunity
      firstTitle={state.title}
      firstSubtitle={state.subtitle}
      firstImage={state.first_image}
      firstContent={state.first_content}
      secondImage={state.second_image}
      secondContent={state.second_content}
    />
    <Volunteering data={state.volunteer_places} />
    <Opportunities />
  </>)
}
export default Internship