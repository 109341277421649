import CommunicationCard from "../../genericComponents/communicationCard/CommunicationCard"
import SectionHeader from "../../genericComponents/sectionHeader/SectionHeader"
import "./employmentService.scss"

const EmploymentService = ({ title, subtitle, content }) => {
  return (<section className="employment-service">
    <SectionHeader
      subtitle={title}
      title={subtitle}
      content={content}
    />
    <div className="employment-service__card">
      <CommunicationCard
        place="Bogatá, Colombia"
        title="Servicios de empleo"
        description="Registrese y encuentre las ofertas laborales de todos los prestadores a nivel Nacional."
        src="https://id.presidencia.gov.co/Galeria_Fotografica/190828-Guajira-encontrado-trabajo-formal-Servicio-Publico-Empleo-1800.jpg"
      />
    </div>
  </section>)
}
export default EmploymentService