import { useEffect, useState } from 'react';
import "./SearchSelect.scss"

const SearchSelect = ({ current, text, icon, optionsList, setFilter }) => {
    const [showOptions, setShowOptions] = useState(false)
    const [filteredOptionList, setFilteredOptionList] = useState([])

    const clickShowOptions = () => setShowOptions(!showOptions)

    const filterOptions = (event) => {
        setFilteredOptionList(
            optionsList.filter(
                option => option.name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        );
    };

    const selectOption = (id) => {
        setFilter(id)
    };

    const handleClickOutside = (event) => {
        if (!(event && event.path && event.path[1] && event.path[1].className === 'search-select__options')) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const name = current && optionsList.length > 0 ? optionsList.filter(opt => opt.id === current)[0].name : text

    return (
        <div className={"search-select"} onClick={clickShowOptions}>
            <span>
                <i className={icon} />
                <input type="text" placeholder={name} onChange={filterOptions}/>
                <i className="ri-arrow-drop-down-line" />
            </span>
            {showOptions &&
                <div className={"search-select__options"}>
                    <div
                        onClick={() => {
                            selectOption(null)
                        }}
                    >
                        ---
                    </div>
                    {filteredOptionList.length > 0? filteredOptionList.map((option, index) =>
                        <div
                            key={`options-${index}`}
                            onClick={() => selectOption(option.id)}
                            style={current === option.id ?
                                { backgroundColor: '#F3F4F6' } :
                                {}
                            }
                        >
                            {option.name}
                        </div>
                    ):optionsList.map((option, index) =>
                        <div
                            key={`options-${index}`}
                            onClick={() => selectOption(option.id)}
                            style={current === option.id ?
                                { backgroundColor: '#F3F4F6' } :
                                {}
                            }
                        >
                            {option.name}
                        </div>
                    )}
                </div>}
        </div >
    );
}

export default SearchSelect;
