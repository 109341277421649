import React from 'react';
import "./Regions.scss"

const Regions = ({content}) => {
    return (
        <div className="region-title">
            <span dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    );
};

export default Regions;
