import React from 'react'
import './hero.scss'
import BackgroundImage from '../../genericComponents/backgroundImage/BackgroundImage'

const Hero = ({ image, title }) => {

  return (<BackgroundImage backgroundPath={image}>
    <div className="hero-study-work">
      <h1 className="hero-study-work__title">{title}</h1>
    </div>
  </BackgroundImage>)
}
export default Hero