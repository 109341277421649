import React from 'react';
import "./HostingService.scss"
import BoxInformationSimple from "../../genericComponents/boxInformationSimple/BoxInformationSimple";

const HostingService = ({ services, title, subtitle }) => {

    return (
        <div id="hosting-service" className="hosting-service">
            <div className="hosting-service__title">{title}</div>
            <div className="hosting-service__subtitle">
                <span>{subtitle}</span>
            </div>
            <div className="hosting-service__list">
                {services.map((hosting, index) =>
                    <BoxInformationSimple
                        key={`box-information-${index}`}
                        text={hosting.summary}
                        title={hosting.name}
                        img={hosting.image}
                        content={hosting.content}

                    />
                )}
            </div>
        </div>
    );
};

export default HostingService;
