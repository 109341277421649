import { useEffect, useState } from 'react'
import BestSpanish from "../../components/learnSpanish/bestSpanish/BestSpanish"
import Bogota from "../../components/learnSpanish/bogota/Bogota"
import Hero from "../../components/learnSpanish/hero/hero"
import KnowMore from "../../components/learnSpanish/knowMore/KnowMore"
import Opportunities from "../../components/studyInColombia/opportunities/Opportunities"
import SpanishChangesTheWorld from "../../components/learnSpanish/spanishChangesTheWorld/SpanishChangesTheWorld"
import { useTranslation } from "react-i18next"
import Axios from 'axios'
import { ENDPOINTS } from '../../constants/endpoints'


const initialState = {
  id: null,
  figures: [],
  places_to_learn_spanish: [],
  principal_content: '',
  principal_image: '',
  principal_subtitle: '',
  principal_title: '',
  second_text: '',
  third_section_image: "",
  third_section_subtitle: '',
  third_section_title: ','
}
const LearnSpanish = (props) => {
  const { i18n } = useTranslation()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'
    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.STUDY_IN_COLOMBIA.LEARN}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])

  return (
    <>
      <Hero image={state.principal_image} />
      <BestSpanish
        title={state.principal_title}
        subtitle={state.principal_subtitle}
        content={state.principal_content}
        placesToLearn={state.places_to_learn_spanish} />
      <SpanishChangesTheWorld content={state.second_text} />
      <KnowMore data={state.figures} />
      <Bogota
        title={state.third_section_title}
        subtitle={state.third_section_subtitle}
        content={state.third_section_content}
        image={state.third_section_image}
      />
      <Opportunities />
    </>)
}
export default LearnSpanish