import "./HeaderHome.scss"
import { useState } from 'react'
import ShowVideo from "./showVideo/showVideo";
import Searcher from "./searcher/searcher";
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";
import Modal from "../../genericComponents/modal/Modal";

const HeaderHome = ({ image, video, title, subtitle, home }) => {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }
    const close = () => {
        setShowModal(false)
    }

    return (
        <>
            <BackgroundImage backgroundPath={image}>
                <ShowVideo title={title} subtitle={subtitle} onClick={openModal} home={home} />
                <Searcher />
            </BackgroundImage>
            {showModal && <Modal close={close}>
                <video src={video} controls autoplay="true" ></video>
            </Modal>}
        </>
    )
}

export default HeaderHome;
