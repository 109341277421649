import './boxWithShadow.scss';

const BoxWithShadow = ({ invert = false, src }) => {
    return (
        <div
            className={`box-with-shadow ${invert && 'box-with-shadow--invert'}`}
        >
            {invert && (
                <div className="box-with-shadow__triangle box-with-shadow__triangle--invert"></div>
            )}
            <div className="box-with-shadow__box">
                <img className="box-with-shadow__image" src={src} alt="Imagen Slider Caja" />
            </div>
            {!invert && <div className="box-with-shadow__triangle"></div>}
        </div>
    );
};
export default BoxWithShadow;
