import React from 'react';
import { useTranslation } from "react-i18next";
import './AccessLinkOptions.scss'
import { useHistory } from "react-router-dom";

const AccessLinkOption = ({ selection, indexLink, active }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const showList = () => {
        if (selection.options.length === 0) {
            goTo(selection.url);
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    return (
        <div id="links"
            className={`link-navigation  link-list__option--${indexLink} ${active && 'active'}`}>
            <span onClick={() => showList()}>{t(selection.name)}</span>
            {selection.options.length > 0 && <div id="options"
                className="link-list__options">
                {selection.options.map((option, index) =>
                    <div
                        key={`options-link-${index}`}
                        onClick={() => goTo(option.url)}>
                        {t(option.name)}
                    </div>
                )}
            </div>}
        </div>
    );
};

export default AccessLinkOption;
