import { useEffect } from 'react'

const useSwipe = (ref, onSwipeLeft, onSwipeRight) => {
  useEffect(() => {
    ref.current.addEventListener('touchstart', handleTouchStart, { passive: true });
    ref.current.addEventListener('touchmove', handleTouchMove, { passive: true });
    const element = ref.current;

    let xStart = null;
    let yStart = null;

    function getTouches(evt) {
      return evt.touches ||
        evt.originalEvent.touches;
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xStart = firstTouch.clientX;
      yStart = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
      if (!xStart || !yStart) {
        return;
      }

      var xEnd = evt.touches[0].clientX;
      var yEnd = evt.touches[0].clientY;

      var xDiff = xStart - xEnd;
      var yDiff = yStart - yEnd;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
        if (xDiff > 0) {
          onSwipeLeft()
        } else {
          onSwipeRight()
        }
      }
      xStart = null;
      yStart = null;
    };

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
    }
  }, [ref, onSwipeLeft, onSwipeRight])
}
export default useSwipe