import Slider from 'react-slick';
import Article from '../../homeComponents/articles/article/Article';
import '../../../styles/slick.min.css';
import './sliderArticles.scss';

const SliderArticles = ({ data }) => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            {data.length > 0 ? (
                <Slider Slider {...settings}>
                    {
                        data.map((article, index) => (
                            <div key={`slider-article-${index}`} className="slide-container">
                                <Article data={article} />
                            </div>
                        ))
                    }
                </Slider>
            ) : <div></div>
            }
        </>
    )
};
export default SliderArticles;
