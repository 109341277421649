import BackgroundBanner from '../../genericComponents/backgroundBanner/BackgroundBanner'
import Image from '../../../assets/images/studyInColombia/learnSpanish/banner.jpg'
import { useTranslation } from 'react-i18next'

const SpanishChangesTheWorld = ({ content }) => {
  const { t } = useTranslation()
  return (
    <BackgroundBanner
      path={Image}
      subtitle={t("learnSpanish:teachingSpanish")}
      title={t("learnSpanish:learnHowSpanishHasChanged")}
      buttonText={t("learnSpanish:seeEducativeOffer")}
      content={content}
      full
    />)
}
export default SpanishChangesTheWorld