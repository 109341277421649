import './reasons.scss'
import { useTranslation } from 'react-i18next'
import BackgroundBanner from '../../genericComponents/backgroundBanner/BackgroundBanner'
import Card from '../../genericComponents/cardWithModal/Card'
import Banner from '../../../assets/images/studyInColombia/banner.jpg'
import Opportunities from '../opportunities/Opportunities'

const Reasons = ({ title, subtitle, content, reasons, secondImage }) => {
  const { t } = useTranslation()
  return (
    <>
      <section className="banner-reasons">
        <div className="banner-reasons__content">
          <h3 className="banner-reasons__subtitle">{title}</h3>
          <h2 className="banner-reasons__title">{subtitle}</h2>
          <p className="banner-reason__content">{content}</p>

        </div>
        <div className="banner-reasons__helper"></div>
        <img className="banner-reasons__image" src={secondImage} alt="Banner Razones para estudiar en Colombia" />

      </section>
      <section className="five-reasons">
        <h2 className="five-reasons__title">{t("studyInColombia:ourFiveReasons")}</h2>
        <div className="five-reasons__container">
          {
            reasons.sort((a, b) => a.order - b.order).map(reason => <Card key={reason.id} {...reason} modal />)
          }
        </div>
      </section>
      <section className="banner-wrapper">
        <BackgroundBanner path={Banner}
          buttonText={t("studyInColombia:seeEducativeOffer")}
          title={t("studyInColombia:knowOurPrograms")}
          subtitle={t("studyInColombia:studyInColombia")} />
      </section>
      <Opportunities />
    </>
  )
}
export default Reasons