import './items.scss'
import Timeline from '../../genericComponents/timeline/Timeline'

const Items = ({ content, data }) => {
  return (
    <section className="ancestral-items">
      <div className="ancestral-items__title" dangerouslySetInnerHTML={{ __html: content }}></div>
      <Timeline data={data} />
    </section>)
}
export default Items