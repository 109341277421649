import "./results.scss"
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import Axios from "axios"
import Result from "./result/Result"
import { ENDPOINTS } from "../../../constants/endpoints"
import Map from "../map/Map"
import Filters from "../filters/Filters"
import Loader from "../../genericComponents/loader/Loader"

const Results = (props) => {
  const [showMap, setShowMap] = useState(false)
  const [programs, setPrograms] = useState([])
  const [loading, setLoading] = useState(true)
  const filters = useSelector(state => state.filters)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setLoading(true)
    const lang = i18n.language === 'es' ? '' : '/en'
    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.EDUCATIVE_OFFER.PROGRAMS}`, {
      params: {
        ...filters
      }
    })
      .then(({ data }) => {
        setPrograms(data.results)
        setLoading(false)
      })
  }, [i18n.language, filters])

  const changeShowMap = () => {
    setShowMap(!showMap)
  }

  return (
    <>
      <Filters showMap={showMap} changeShowMap={changeShowMap} />
      <div className="results__container">
        <section className={`results__list ${showMap && 'open'}`}>
          {programs.length > 0 && !loading &&
            programs.map((program) => (
              <Result
                key={program.id}
                id={program.id}
                image={program.image}
                title={program.name}
                institution={program.institution}
                place={program.city}
                description={program.description}
                whatsApp={program.whatsapp_url}
                startDate={program.start_date}
                programType={program.program_type}
                cost={program.cost}
                registration={program.registration_url}
                open={showMap}
              />
            ))
          }
          {loading && <Loader />}
          {programs.length === 0 && !loading && <h4 className="programs-not-found">{t("programs:notFound")}</h4>}
        </section>
        <section className={`map__container ${showMap && 'open'}`}>
          {showMap &&
            <Map zoom={6}
              center={[4.0744423, -73.5851376]}
              markers={programs} />
          }
        </section>
      </div>
    </>
  )
}
export default Results