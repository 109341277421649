import './hero.scss'
import BackgroundImage from '../../genericComponents/backgroundImage/BackgroundImage'

const Hero = ({ image, title }) => {

  return (<BackgroundImage backgroundPath={image} overlay>
    <div className="university-city-hero">
      <h1 className="university-city-hero__title">{title}</h1>
    </div>
  </BackgroundImage>)
}
export default Hero