import React from "react";
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage"
import './HeroStudyInColombia.scss';

const HeroStudyInColombia = ({ image }) => {
  return (<>
    <BackgroundImage backgroundPath={image} >
      <div className="hero-study" />
    </BackgroundImage>
  </>)
}
export default HeroStudyInColombia