import { useEffect, useState } from 'react'
import Axios from "axios"
import { ENDPOINTS } from "../../constants/endpoints"
import { useTranslation } from 'react-i18next'
import Hero from '../../components/ancestralKnowledge/hero/Hero'
import Opportunities from '../../components/studyInColombia/opportunities/Opportunities'
import Items from '../../components/ancestralKnowledge/items/Items'

const initialState = {
  title: '',
  content: '',
  principal_image: '',
  ancestral_knowledge_items: []
}
const AncestralKnowledge = (props) => {
  const { i18n } = useTranslation()
  const [state, setState] = useState(initialState)
  useEffect(() => {
    const lang = i18n.language === 'es' ? '' : '/en'

    Axios.get(`${ENDPOINTS.BASE}${lang}${ENDPOINTS.DISCOVER_COLOMBIA.ANCESTRAL}`)
      .then(({ data }) => {
        setState(data[0])
      })
  }, [i18n.language])
  return (<>
    <Hero title={state.title} image={state.principal_image} />
    <Items content={state.content} data={state.ancestral_knowledge_items} />
    <Opportunities />
  </>)
}
export default AncestralKnowledge