import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import * as Types from "../../store/types";
import axios from "axios";
import {ENDPOINTS} from "../../constants/endpoints";
import HeroAfterStudy from "../../components/afterStudy/heroAfterStudy/HeroAfterStudy";
import PublicEmploymentService from "../../components/afterStudy/publicEmpoymentService/PublicEmploymentService";
import Remember from "../../components/afterStudy/remember/Remember";
import Loader from "../../components/genericComponents/loader/Loader";
import Options from "../../components/studyAndWork/options/Options";

const initialState = {
    id: 0,
    principal_image: '',
    principal_title: '',
    options: [],
    second_section_content: '',
    second_section_subtitle: '',
    second_section_title: '',
    second_subtitle: '',
    image: '',
    subtitle: '',
    title: '',
    showLoading: true
}

const AfterStudy = () => {
    const [state, setState] = useState(initialState);
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch({type: Types.HIDDEN_HEADER, payload: true})
            setState({showLoading: true});
            const LANGUAGE = i18n.language === 'en' ? '/en' : ''
            let {data} = await axios.get(
                `${ENDPOINTS.BASE}${LANGUAGE}${ENDPOINTS.LIVE_IN_COLOMBIA.MAIN}${ENDPOINTS.LIVE_IN_COLOMBIA.FINISH_STUDIES}`);
            const {
                id,
                principal_image,
                principal_title,
                options,
                second_section_content,
                second_section_subtitle,
                second_section_title,
                second_subtitle,
                image,
                title,
                subtitle
            } = data[0];
            setState({
                id,
                principal_image,
                principal_title,
                options,
                second_section_content,
                second_section_subtitle,
                second_section_title,
                second_subtitle,
                image,
                title,
                subtitle
            })
        }

        fetchData().then(() => dispatch({type: Types.HIDDEN_HEADER, payload: false}))
    }, [i18n.language])

    return (
        <>
            {state.showLoading ? <div className="without-home">
                    <Loader/>
                </div> :
                <div>
                    <HeroAfterStudy image={state.principal_image}/>
                    <PublicEmploymentService
                        second_subtitle={state.second_subtitle}
                        subtitle={state.subtitle}
                        options={state.options}
                        title={state.title}/>
                    <Remember
                        image={state.image}
                        second_subtitle={state.second_subtitle}
                        second_section_title={state.second_section_title}
                        second_section_subtitle={state.second_section_subtitle}
                        second_section_content={state.second_section_content}/>
                    <Options/>
                </div>}
        </>
    );
};

export default AfterStudy;
