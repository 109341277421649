import './heroHistory.scss'
import BackgroundImage from "../../genericComponents/backgroundImage/BackgroundImage";

const HeroHistory = ({ title, image }) => {
    return (
        <BackgroundImage backgroundPath={image}>
            <section className="history-hero">
                <h3 className="history-hero__title">
                    {title}
                </h3>
            </section>
        </BackgroundImage>
    )
}
export default HeroHistory